/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Countries flags
import US from "assets/images/icons/flags/US.png";
import DE from "assets/images/icons/flags/DE.png";
import GB from "assets/images/icons/flags/GB.png";
import BR from "assets/images/icons/flags/BR.png";

const salesTableData = [
  {
    product: [US, "Fish"],
    sales: 2500,
    value: "$230,900",
    bounce: "29.9%",
  },
  {
    product: [DE, "Fish"],
    sales: "3.900",
    value: "$440,000",
    bounce: "40.22%",
  },
  {
    product: [GB, "Fish"],
    sales: "1.400",
    value: "$190,700",
    bounce: "23.44%",
  },
  { product: [BR, "Fish"], sales: 562, value: "$143,960", bounce: "32.14%" },
];

export default salesTableData;
