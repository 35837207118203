import TextField from "@mui/material/TextField";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";

import { useState, useEffect } from "react";
import MDButton from "components/MDButton";
// import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import Divider from "@mui/material/Divider";

// import FormGroup from "@mui/material/FormGroup";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import Checkbox from "@mui/material/Checkbox";

import axios from "axios";
import toast from "react-hot-toast";
// import dateFormat from "dateformat";

import ProductSkeleton from "examples/Skeleton/product";
// import imagePlaceholder from "assets/images/image_placeholder.jpeg";

function EditDiscount(props) {
  const { setOpenDrawer, setRefresh, dataRecord, setIssetData } = props;
  const [validateError, setError] = useState([]);
  const [loading, setLoading] = useState(true);
  const [disabled, setDisabled] = useState(false);

  // const [controller] = useMaterialUIController();
  // const { darkMode } = controller;

  const [inputData, setInputData] = useState([]);

  useEffect(() => {
    axios.get(`/api/discount-edit/${dataRecord}`).then((res) => {
      if (res.data.status === 200) {
        setInputData(res.data.discount);
      } else {
        setOpenDrawer(false);
        toast.error(res.data.message);
      }
      setLoading(false);
      setDisabled(false);
    });
  }, []);

  const handleInput = (e) => {
    e.persist();
    setInputData({ ...inputData, [e.target.name]: e.target.value });
  };

  const handClose = () => {
    setOpenDrawer(false);
    setIssetData(false);
  };

  const updateForm = (e) => {
    e.preventDefault();

    setDisabled(true);

    const data = {
      title: inputData.title,
      code: inputData.code,
      quantity: inputData.quantity,
      start_date: inputData.start_date,
      end_date: inputData.end_date,
    };

    axios.put(`/api/discount-update/${dataRecord}`, data).then((res) => {
      setError([]);
      if (res.data.status === 200) {
        toast.success(res.data.message);
        setOpenDrawer(false);
        setRefresh(2);
        setIssetData(false);
      } else if (res.data.status === 202) {
        toast.error(res.data.message);
      } else if (res.data.status === 422) {
        setError(res.data.errors);
      } else if (res.data.status === 404) {
        toast.error(res.data.message);
      }
      setDisabled(false);
    });
  };

  return (
    <div>
      {loading ? (
        <ProductSkeleton />
      ) : (
        <MDBox component="form" onSubmit={updateForm}>
          <Grid container alignItems="center">
            <Grid item xs={12} p={1}>
              <TextField
                fullWidth
                helperText={validateError.title ? validateError.title : " "}
                error={!!validateError.title}
                label="Title"
                type="text"
                name="title"
                onChange={handleInput}
                defaultValue={inputData.title}
                variant="standard"
              />
            </Grid>
            <Grid item xs={12} p={1}>
              <TextField
                fullWidth
                helperText={validateError.code ? validateError.code : " "}
                error={!!validateError.code}
                label="Code"
                type="text"
                name="code"
                onChange={handleInput}
                defaultValue={inputData.code}
                variant="standard"
              />
            </Grid>
            <Grid item xs={12} p={1}>
              <TextField
                fullWidth
                helperText={validateError.quantity ? validateError.quantity : " "}
                error={!!validateError.quantity}
                label="Quantity (Percentage)"
                type="number"
                name="quantity"
                onChange={handleInput}
                defaultValue={inputData.quantity}
                variant="standard"
              />
            </Grid>
            <Grid item xs={12} p={1}>
              <TextField
                fullWidth
                helperText={validateError.start_date ? validateError.start_date : " "}
                error={!!validateError.start_date}
                label="Start Date"
                type="date"
                name="start_date"
                onChange={handleInput}
                defaultValue={inputData.start_date}
                variant="standard"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} p={1}>
              <TextField
                fullWidth
                helperText={validateError.end_date ? validateError.end_date : " "}
                error={!!validateError.end_date}
                label="End Date"
                type="date"
                name="end_date"
                onChange={handleInput}
                defaultValue={inputData.end_date}
                variant="standard"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>

          <Divider />

          <MDBox
            // sx={{
            //   display: "flex",
            //   alignContent: "flex-end",
            // }}
            mb={2}
          >
            <Grid item md={2} mt={4} mb={2}>
              <MDButton
                size="small"
                color="dark"
                variant="gradient"
                type="submit"
                disabled={disabled}
              >
                <Icon>done</Icon>&nbsp;
                {disabled ? "Please Wait" : "Save"}
              </MDButton>
              &nbsp;
              <MDButton size="small" color="light" variant="gradient" onClick={handClose}>
                <Icon>close</Icon>&nbsp; Close
              </MDButton>
            </Grid>
          </MDBox>
        </MDBox>
      )}
    </div>
  );
}

export default EditDiscount;
