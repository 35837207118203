// import * as React from "react";
import TextField from "@mui/material/TextField";

import { useState } from "react";
import MDButton from "components/MDButton";
// import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import Divider from "@mui/material/Divider";

import axios from "axios";
import toast from "react-hot-toast";
// import { useNavigate } from "react-router-dom";
// import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React contexts
// import { useMaterialUIController } from "context";

import ProductSkeleton from "examples/Skeleton/product";
import IconThump from "assets/images/image_placeholder.jpeg";
import BannerThump from "assets/images/thump/1920_637.jpg";
import { Grid } from "@mui/material";

function CreateDestination(props) {
  const { setOpenDrawer, setRefresh, setIssetData } = props;
  const [validateError, setError] = useState([]);
  const [loading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  // const [controller] = useMaterialUIController();
  // const { darkMode } = controller;
  // const navigate = useNavigate();

  const [preview, setPreview] = useState([]);
  const [inputPicture, setInputPicture] = useState([]);

  const [inputData, setInputData] = useState({
    name: "",
    title: "",
    tiny_title: "",
    sub_title: "",
    description: "",
  });

  const handleInput = (e) => {
    e.persist();
    setInputData({ ...inputData, [e.target.name]: e.target.value });
  };

  const handleImage = (e) => {
    e.persist();
    setInputPicture({ ...inputPicture, [e.target.name]: e.target.files[0] });

    const objectUrl = URL.createObjectURL(e.target.files[0]);
    setPreview({ ...preview, [e.target.name]: objectUrl });
  };

  const handClose = () => {
    setOpenDrawer(false);
    setIssetData(false);
  };

  const createForm = (e) => {
    e.preventDefault();

    setDisabled(true);

    const formData = new FormData();
    formData.append("name", inputData.name);
    formData.append("title", inputData.title ?? "");
    formData.append("tiny_title", inputData.tiny_title ?? "");
    formData.append("sub_title", inputData.sub_title ?? "");
    formData.append("description", inputData.description ?? "");
    formData.append("icon", inputPicture.icon);
    formData.append("banner", inputPicture.banner);

    axios.post(`/api/category-store`, formData).then((res) => {
      setError([]);
      if (res.data.status === 200) {
        toast.success(res.data.message);
        setOpenDrawer(false);
        setRefresh(2);
        setIssetData(false);
      } else if (res.data.status === 202) {
        toast.error(res.data.message);
      } else if (res.data.status === 422) {
        toast.error("Please check the fields");
        setError(res.data.errors);
      } else if (res.data.status === 404) {
        toast.error(res.data.message);
      }
      setDisabled(false);
    });
  };

  return (
    <div>
      {loading ? (
        <ProductSkeleton />
      ) : (
        <MDBox component="form" onSubmit={createForm}>
          <Grid container alignItems="center">
            <Grid item xs={12} py={1}>
              <TextField
                fullWidth
                helperText={validateError.name ? validateError.name : " "}
                error={!!validateError.name}
                label="Name"
                type="text"
                name="name"
                onChange={handleInput}
                defaultValue={inputData.name}
                variant="standard"
              />
            </Grid>
            <Grid item xs={12} py={1}>
              <TextField
                fullWidth
                helperText={validateError.title ? validateError.title : " "}
                error={!!validateError.title}
                label="Title"
                type="text"
                name="title"
                onChange={handleInput}
                defaultValue={inputData.title}
                variant="standard"
              />
            </Grid>
            <Grid item xs={12} py={1}>
              <TextField
                fullWidth
                helperText={validateError.tiny_title ? validateError.tiny_title : " "}
                error={!!validateError.tiny_title}
                label="Tiny Title"
                type="text"
                name="tiny_title"
                onChange={handleInput}
                defaultValue={inputData.tiny_title}
                variant="standard"
              />
            </Grid>
            <Grid item xs={12} py={1}>
              <TextField
                fullWidth
                helperText={validateError.sub_title ? validateError.sub_title : " "}
                error={!!validateError.sub_title}
                label="Sub Title"
                type="text"
                name="sub_title"
                onChange={handleInput}
                defaultValue={inputData.sub_title}
                variant="standard"
              />
            </Grid>
            <Grid item xs={12} py={1}>
              <TextField
                fullWidth
                multiline
                rows={2}
                helperText={validateError.description ? validateError.description : " "}
                error={!!validateError.description}
                label="Description"
                type="text"
                name="description"
                onChange={handleInput}
                defaultValue={inputData.description}
                variant="standard"
              />
            </Grid>
            <Grid item xs={12} md={6} py={3} pr={2}>
              {preview.icon ? (
                <MDBox
                  component="img"
                  src={preview.icon}
                  alt="pic"
                  sx={{
                    objectFit: "cover",
                    width: "100%",
                    height: "auto",
                    borderRadius: "5px",
                  }}
                />
              ) : (
                <MDBox
                  component="img"
                  src={IconThump}
                  alt="pic"
                  sx={{
                    objectFit: "cover",
                    width: "100%",
                    height: "auto",
                    borderRadius: "5px",
                  }}
                />
              )}
            </Grid>
            <Grid item xs={12} md={6} pb={3}>
              <TextField
                fullWidth
                helperText={validateError.icon ? validateError.icon : " "}
                error={!!validateError.icon}
                label="Icon (type: svg, ratio: 1*1)"
                type="file"
                accept="image/*"
                // accept=".jpg, .jpeg, .png"
                name="icon"
                multiple
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={handleImage}
                variant="standard"
                sx={{ borderBottom: "none" }}
              />
            </Grid>
            <Grid item xs={12} md={6} py={3} pr={2}>
              {preview.banner ? (
                <MDBox
                  component="img"
                  src={preview.banner}
                  alt="pic"
                  sx={{
                    objectFit: "cover",
                    width: "100%",
                    height: "auto",
                    borderRadius: "5px",
                  }}
                />
              ) : (
                <MDBox
                  component="img"
                  src={BannerThump}
                  alt="pic"
                  sx={{
                    objectFit: "cover",
                    width: "100%",
                    height: "auto",
                    borderRadius: "5px",
                  }}
                />
              )}
            </Grid>
            <Grid item xs={12} md={6} pb={3}>
              <TextField
                fullWidth
                helperText={validateError.banner ? validateError.banner : " "}
                error={!!validateError.banner}
                label="Banner (type: image/*, res: 1920*637)"
                type="file"
                accept="image/*"
                // accept=".jpg, .jpeg, .png"
                name="banner"
                multiple
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={handleImage}
                variant="standard"
                sx={{ borderBottom: "none" }}
              />
            </Grid>
          </Grid>

          <Divider />

          <MDBox mb={2}>
            <MDButton
              size="small"
              color="dark"
              variant="gradient"
              type="submit"
              disabled={disabled}
            >
              {disabled ? "Please Wait" : "Submit"}
            </MDButton>
            &nbsp;
            <MDButton size="small" color="light" variant="gradient" onClick={handClose}>
              Close
            </MDButton>
          </MDBox>
        </MDBox>
      )}
    </div>
  );
}

export default CreateDestination;
