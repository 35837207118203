import { useState, useEffect } from "react";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Typography from "@mui/material/Typography";

// Authentication layout components
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";

// Image
import bgImage from "assets/images/illustrations/reset-password.png";

// custom
import axios from "axios";
import toast from "react-hot-toast";

function ResetPassword() {
  const [showStepOne, setShowStepOne] = useState(true);
  const [showStepTwo, setShowStepTwo] = useState(false);
  const [showStepThree, setShowStepThree] = useState(false);
  const [disabled, setDisabled] = useState(false);
  //   const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const navigate = useNavigate();
  const [validateError, setError] = useState([]);
  const [dataInput, setDataInput] = useState([]);

  useEffect(() => {
    setShowStepOne(true);
    setShowStepTwo(false);
    setShowStepThree(false);
    setDisabled(false);
  }, []);

  const handleInput = (e) => {
    e.persist();
    setDataInput({ ...dataInput, [e.target.name]: e.target.value });
  };

  const registerSubmit = (e) => {
    e.preventDefault();

    setDisabled(true);

    const data = {
      email: dataInput.email,
    };

    axios.post(`/api/reset-email`, data).then((res) => {
      setError([]);
      if (res.data.status === 200) {
        toast.success(res.data.message);
        setShowStepOne(false);
        setShowStepTwo(true);
        setShowStepThree(false);
      } else if (res.data.status === 422) {
        setError(res.data.errors);
        // toast.error("Fill in fields correctly!");
      } else if (res.data.status === 400) {
        toast.error("error");
        // console.log(res.data.errors);
      }
      setDisabled(false);
    });
  };

  const codeSubmit = (e) => {
    e.preventDefault();

    setDisabled(true);

    const data = {
      email: dataInput.email,
      code: dataInput.code,
    };

    axios.post(`/api/reset-code`, data).then((res) => {
      setError([]);
      if (res.data.status === 200) {
        toast.success(res.data.message);
        setShowStepOne(false);
        setShowStepTwo(false);
        setShowStepThree(true);
      } else if (res.data.status === 422) {
        setError(res.data.errors);
        toast.error(res.data.errors.code);
      } else if (res.data.status === 400) {
        toast.error("error");
        // console.log(res.data.errors);
      }
      setDisabled(false);
    });
  };

  const passwordSubmit = (e) => {
    e.preventDefault();

    setDisabled(true);

    const data = {
      email: dataInput.email,
      code: dataInput.code,
      password: dataInput.password,
      password_confirmation: dataInput.password_confirmation,
    };

    axios.post(`/api/reset-password`, data).then((res) => {
      setError([]);
      if (res.data.status === 200) {
        setDataInput([]);
        toast.success(res.data.message);
        navigate(`/`);
        // window.location.reload();
      } else if (res.data.status === 422) {
        setError(res.data.errors);
        toast.error(res.data.errors.code);
      } else if (res.data.status === 402) {
        setShowStepOne(true);
        setShowStepTwo(false);
        setShowStepThree(false);
        toast.error(res.data.message);
      } else if (res.data.status === 400) {
        toast.error("error");
        // console.log(res.data.errors);
      }
      setDisabled(false);
    });
  };

  let description;
  if (showStepOne) {
    description = "To login in to your account, enter your email";
  } else if (showStepTwo) {
    description = `Code has been sent to this address ${dataInput.email}`;
  } else if (showStepThree) {
    description = "Set your password here";
  } else {
    description = "To login in to your account, enter your email";
  }

  return (
    <IllustrationLayout
      title="Reset Password"
      description={description}
      //   description="To login in to your account, enter your email"
      illustration={bgImage}
    >
      {showStepOne && (
        <MDBox component="form" onSubmit={registerSubmit}>
          <MDBox mb={2}>
            <MDInput type="email" label="Email" name="email" fullWidth onChange={handleInput} />
            <Typography variant="caption" color="error" mt={1} display="block">
              {validateError.email}
            </Typography>
          </MDBox>
          <MDBox mt={4} mb={1}>
            <MDButton
              type="submit"
              variant="gradient"
              color="dark"
              size="large"
              disabled={disabled}
              fullWidth
            >
              {disabled ? "Please Wait" : "Next"}
            </MDButton>
          </MDBox>
        </MDBox>
      )}
      {showStepTwo && (
        <MDBox component="form" onSubmit={codeSubmit}>
          <MDBox mb={2}>
            <MDInput type="text" label="Code" name="code" fullWidth onChange={handleInput} />
            <Typography variant="caption" color="error" mt={1} display="block">
              {validateError.code}
            </Typography>
          </MDBox>
          <MDBox mt={4} mb={1}>
            <MDButton
              type="submit"
              variant="gradient"
              color="dark"
              size="large"
              fullWidth
              disabled={disabled}
            >
              {disabled ? "Please Wait" : "Verify Code"}
            </MDButton>
          </MDBox>
        </MDBox>
      )}
      {showStepThree && (
        <MDBox component="form" onSubmit={passwordSubmit}>
          <MDBox mb={2}>
            <MDInput
              type="password"
              label="Password"
              name="password"
              fullWidth
              onChange={handleInput}
            />
            <Typography variant="caption" color="error" mt={1} display="block">
              {validateError.password}
            </Typography>
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              type="password"
              label="Confirm Password"
              name="password_confirmation"
              fullWidth
              onChange={handleInput}
            />
            <Typography variant="caption" color="error" mt={1} display="block">
              {validateError.password_confirmation}
            </Typography>
          </MDBox>
          <MDBox mt={4} mb={1}>
            <MDButton
              type="submit"
              variant="gradient"
              color="dark"
              size="large"
              fullWidth
              disabled={disabled}
            >
              {disabled ? "Please Wait" : "Update"}
            </MDButton>
          </MDBox>
        </MDBox>
      )}
      <MDBox mt={3} textAlign="center">
        <MDTypography variant="button" color="text">
          Back to home page?{" "}
          <MDTypography
            component={Link}
            to="/"
            variant="button"
            color="info"
            fontWeight="medium"
            textGradient
          >
            click here
          </MDTypography>
        </MDTypography>
      </MDBox>
    </IllustrationLayout>
  );
}

export default ResetPassword;
