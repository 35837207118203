import TextField from "@mui/material/TextField";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";

import { useState, useEffect } from "react";
import MDButton from "components/MDButton";
// import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import Divider from "@mui/material/Divider";

import axios from "axios";
import toast from "react-hot-toast";

// import { useMaterialUIController } from "context";
import ProductSkeleton from "examples/Skeleton/product";
import DataTable from "examples/Tables/DataTable";
import PreviewDrawer from "../drawer/preview";

function EditProduct(props) {
  const { setOpenDrawer, setRefresh, dataRecord, setIssetData } = props;
  const [validateError, setError] = useState([]);
  const [loading, setLoading] = useState(true);
  const [disabled, setDisabled] = useState(false);

  // const [controller] = useMaterialUIController();
  // const { darkMode } = controller;

  // preview pdf
  const [openPreview, setOpenPreview] = useState(false);
  const [refreshPreview, setRefreshPreview] = useState(0);
  const [docType, setDocType] = useState();

  const [inputData, setInputData] = useState([]);

  useEffect(() => {
    axios.get(`/api/order-edit/${dataRecord}`).then((res) => {
      if (res.data.status === 200) {
        setInputData(res.data.order);
      } else {
        setOpenDrawer(false);
        toast.error(res.data.message);
      }
      setLoading(false);
      setDisabled(false);
    });
  }, []);

  const handleInput = (e) => {
    e.persist();
    setInputData({ ...inputData, [e.target.name]: e.target.value });
  };

  const handleSelect = (event) => {
    setInputData({ ...inputData, [event.target.name]: event.target.value });
  };

  const handClose = () => {
    setOpenDrawer(false);
    setIssetData(false);
  };

  const updateForm = (e) => {
    e.preventDefault();

    setDisabled(true);

    const data = {
      invoice_number: inputData.invoice_number,
      invoice_date: inputData.invoice_date,
      receipt_date: inputData.receipt_date,
      status: inputData.status,
      name: inputData.name,
      email: inputData.email,
      phone: inputData.phone,
      alternate_phone_1: inputData.alternate_phone_1,
      address: inputData.address,
      landmark: inputData.landmark,
      zip_code: inputData.zip_code,
    };

    axios.put(`/api/order-update/${dataRecord}`, data).then((res) => {
      setError([]);
      if (res.data.status === 200) {
        toast.success(res.data.message);
        // setOpenDrawer(false);
        setRefresh(2);
        setRefreshPreview(refreshPreview + 1);
        // setIssetData(false);
      } else if (res.data.status === 202) {
        toast.error(res.data.message);
      } else if (res.data.status === 422) {
        setError(res.data.errors);
      } else if (res.data.status === 404) {
        toast.error(res.data.message);
      }
      setDisabled(false);
    });
  };

  // preview pdf function
  const handlePreview = (e, type) => {
    e.preventDefault();

    setDocType(type);
    setOpenPreview(true);
    setRefreshPreview(refreshPreview + 1);
  };

  return (
    <div>
      {loading ? (
        <ProductSkeleton />
      ) : (
        <>
          <DataTable
            isSorted={false}
            showTotalEntries={false}
            entriesPerPage={false}
            table={{
              columns: [
                { Header: "name", accessor: "name" },
                { Header: "price", accessor: "price" },
                { Header: "qty", accessor: "qty", align: "right" },
              ],
              rows: inputData.order_products.map((row) => ({
                name: row.name,
                price: row.price - row.discount,
                qty: row.qty,
              })),
            }}
          />
          <MDBox
            my={2}
            pb={2}
            px={2}
            // sx={{ bgcolor: darkMode ? "#28334f" : "#f1f1f1", borderRadius: "5px" }}
          >
            <Grid mt={1} container direction="column" alignItems="flex-end">
              <Grid item xs={12} p={0}>
                <MDBox>
                  <MDTypography variant="caption" fontWeight="medium">
                    Sub Total :&nbsp;
                  </MDTypography>
                  <MDTypography variant="caption">{inputData.products_subtotal}</MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} p={0}>
                <MDBox>
                  <MDTypography variant="caption" fontWeight="medium">
                    Discount :&nbsp;
                  </MDTypography>
                  <MDTypography variant="caption">{inputData.products_discount_price}</MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} p={0}>
                <MDBox>
                  <MDTypography variant="caption" fontWeight="medium">
                    Vat :&nbsp;
                  </MDTypography>
                  <MDTypography variant="caption">{inputData.products_vat}</MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} p={0}>
                <MDBox>
                  <MDTypography variant="caption" fontWeight="medium">
                    Grand Total :&nbsp;
                  </MDTypography>
                  <MDTypography variant="caption">{inputData.products_grand_total}</MDTypography>
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
          <MDBox component="form" onSubmit={updateForm}>
            <Grid container alignItems="center">
              <Grid item xs={12} md={6} p={1}>
                <TextField
                  fullWidth
                  helperText={validateError.invoice_number ? validateError.invoice_number : " "}
                  error={!!validateError.invoice_number}
                  label="Invoice Number"
                  type="text"
                  name="invoice_number"
                  onChange={handleInput}
                  defaultValue={inputData.invoice_number}
                  variant="standard"
                />
              </Grid>
              <Grid item xs={12} md={6} p={1}>
                <TextField
                  fullWidth
                  helperText={validateError.invoice_date ? validateError.invoice_date : " "}
                  error={!!validateError.invoice_date}
                  label="Invoice Date"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="invoice_date"
                  onChange={handleInput}
                  defaultValue={inputData.invoice_date}
                  variant="standard"
                />
              </Grid>

              <Grid item xs={12} md={6} p={1}>
                <TextField
                  fullWidth
                  helperText={validateError.status ? validateError.status : " "}
                  error={!!validateError.status}
                  label="Payment Status"
                  name="status"
                  defaultValue={inputData.status}
                  onChange={handleSelect}
                  variant="standard"
                  style={{ paddingTop: "8px" }}
                  select
                >
                  <MenuItem value={3}>Refunded</MenuItem>
                  <MenuItem value={2}>Paid</MenuItem>
                  <MenuItem value={1}>Not Paid</MenuItem>
                  <MenuItem value={0}>Canceled</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} md={6} p={1}>
                <TextField
                  fullWidth
                  helperText={validateError.receipt_date ? validateError.receipt_date : " "}
                  error={!!validateError.receipt_date}
                  label="Receipt Date"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="receipt_date"
                  onChange={handleInput}
                  defaultValue={inputData.receipt_date}
                  variant="standard"
                />
              </Grid>
              <Grid item xs={12} py={2}>
                <Divider />
              </Grid>
              <Grid item xs={12} md={6} p={1}>
                <TextField
                  fullWidth
                  helperText={validateError.name ? validateError.name : " "}
                  error={!!validateError.name}
                  label="Name"
                  type="text"
                  name="name"
                  onChange={handleInput}
                  defaultValue={inputData.name}
                  variant="standard"
                />
              </Grid>
              <Grid item xs={12} md={6} p={1}>
                <TextField
                  fullWidth
                  helperText={validateError.email ? validateError.email : " "}
                  error={!!validateError.email}
                  label="Email Id"
                  type="email"
                  name="email"
                  onChange={handleInput}
                  defaultValue={inputData.email}
                  variant="standard"
                />
              </Grid>
              <Grid item xs={12} md={6} p={1}>
                <TextField
                  fullWidth
                  helperText={validateError.phone ? validateError.phone : " "}
                  error={!!validateError.phone}
                  label="Phone Number"
                  type="tel"
                  name="phone"
                  onChange={handleInput}
                  defaultValue={inputData.phone}
                  variant="standard"
                />
              </Grid>
              <Grid item xs={12} p={1}>
                <TextField
                  fullWidth
                  multiline
                  rows={2}
                  helperText={validateError.address ? validateError.address : " "}
                  error={!!validateError.address}
                  label="Address"
                  type="text"
                  name="address"
                  onChange={handleInput}
                  defaultValue={inputData.address}
                  variant="standard"
                />
              </Grid>
              {/* <Grid item xs={12} md={6} p={1}>
      <TextField
        fullWidth
        helperText={validateError.city ? validateError.city : " "}
        error={!!validateError.city}
        label="City"
        type="text"
        name="city"
        onChange={handleInput}
        defaultValue={inputData.city}
        variant="standard"
      />
    </Grid>
    <Grid item xs={12} md={6} p={1}>
      <TextField
        fullWidth
        helperText={validateError.state ? validateError.state : " "}
        error={!!validateError.state}
        label="State"
        type="text"
        name="state"
        onChange={handleInput}
        defaultValue={inputData.state}
        variant="standard"
      />
    </Grid>
    <Grid item xs={12} md={6} p={1}>
      <TextField
        fullWidth
        helperText={validateError.country ? validateError.country : " "}
        error={!!validateError.country}
        label="Country"
        type="text"
        name="country"
        onChange={handleInput}
        defaultValue={inputData.country}
        variant="standard"
      />
    </Grid> */}
              <Grid item xs={12} md={6} p={1}>
                <TextField
                  fullWidth
                  helperText={validateError.landmark ? validateError.landmark : " "}
                  error={!!validateError.landmark}
                  label="Landmark"
                  type="text"
                  name="landmark"
                  onChange={handleInput}
                  defaultValue={inputData.landmark}
                  variant="standard"
                />
              </Grid>
              <Grid item xs={12} md={6} p={1}>
                <TextField
                  fullWidth
                  helperText={validateError.zip_code ? validateError.zip_code : " "}
                  error={!!validateError.zip_code}
                  label="Zip Code"
                  type="text"
                  name="zip_code"
                  onChange={handleInput}
                  defaultValue={inputData.zip_code}
                  variant="standard"
                />
              </Grid>
            </Grid>

            <Divider />

            <MDBox
              // sx={{
              //   display: "flex",
              //   alignContent: "flex-end",
              // }}
              mb={2}
            >
              <Grid item md={2} mt={4} mb={2}>
                <MDButton
                  size="small"
                  color="dark"
                  variant="gradient"
                  type="submit"
                  disabled={disabled}
                >
                  <Icon>done</Icon>&nbsp;
                  {disabled ? "Please Wait" : "Save"}
                </MDButton>
                &nbsp;
                <MDButton
                  size="small"
                  color="dark"
                  variant="gradient"
                  onClick={(e) => handlePreview(e, "invoice")}
                  disabled={disabled}
                >
                  <Icon>receipt_long</Icon>&nbsp;
                  {disabled ? "Please Wait" : "Invoice"}
                </MDButton>
                &nbsp;
                <MDButton
                  size="small"
                  color="dark"
                  variant="gradient"
                  onClick={(e) => handlePreview(e, "receipt")}
                  disabled={disabled}
                >
                  <Icon>receipt</Icon>&nbsp;
                  {disabled ? "Please Wait" : "Receipt"}
                </MDButton>
                &nbsp;
                <MDButton size="small" color="light" variant="gradient" onClick={handClose}>
                  <Icon>close</Icon>&nbsp; Close
                </MDButton>
              </Grid>
            </MDBox>
          </MDBox>
        </>
      )}
      <PreviewDrawer
        openPreview={openPreview}
        setOpenPreview={setOpenPreview}
        dataRecord={dataRecord}
        docType={docType}
        setDocType={setDocType}
        refreshPreview={refreshPreview}
      />
    </div>
  );
}

export default EditProduct;
