import MDBox from "components/MDBox";
import Skeleton from "@mui/material/Skeleton";

function ProductSkeleton() {
  return (
    <>
      <MDBox pt={2}>
        <Skeleton sx={{ borderRadius: "4px" }} height={40} variant="rectangular" />
      </MDBox>
      <MDBox pt={4}>
        <Skeleton sx={{ borderRadius: "4px" }} height={40} variant="rectangular" />
      </MDBox>
      <MDBox pt={4}>
        <Skeleton sx={{ borderRadius: "4px" }} height={40} variant="rectangular" />
      </MDBox>
      <MDBox pt={4}>
        <Skeleton sx={{ borderRadius: "4px" }} height={40} variant="rectangular" />
      </MDBox>
      <MDBox pt={4}>
        <Skeleton sx={{ borderRadius: "4px" }} height={40} variant="rectangular" />
      </MDBox>
      <MDBox pt={5}>
        <Skeleton sx={{ borderRadius: "4px", width: "50%" }} height={40} variant="rectangular" />
      </MDBox>
    </>
  );
}

export default ProductSkeleton;
