import MDBox from "components/MDBox";
import Skeleton from "@mui/material/Skeleton";

function ProductSkeleton() {
  return (
    <MDBox pt={2}>
      <Skeleton sx={{ height: "75vh" }} variant="rounded" animation="wave" />
    </MDBox>
  );
}

export default ProductSkeleton;
