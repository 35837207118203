/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Switch from "@mui/material/Switch";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";

// Image
import bgImage from "assets/images/illustrations/login.png";
// import bgImage from "assets/images/kals-slide.jpeg";

// custom
import axios from "axios";
// import swal from "sweetalert";
import toast from "react-hot-toast";
import Typography from "@mui/material/Typography";

function login() {
  const [rememberMe, setRememberMe] = useState(false);
  const [disabled, setDisabled] = useState(false);
  // const { setAuthenticated } = props;

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const navigate = useNavigate();

  const [loginInput, setLogin] = useState({
    email: "",
    password: "",
    error_list: [],
  });

  const handleInput = (e) => {
    e.persist();
    setLogin({ ...loginInput, [e.target.name]: e.target.value });
  };

  const loginSubmit = (e) => {
    e.preventDefault();

    setDisabled(true);

    const data = {
      email: loginInput.email,
      password: loginInput.password,
    };

    axios.get(`/sanctum/csrf-cookie`).then(() => {
      axios.post(`api/login`, data).then((res) => {
        if (res.data.status === 200) {
          localStorage.setItem("auth_token", res.data.token);
          localStorage.setItem("auth_name", res.data.username);
          // setAuthenticated(true);
          toast.success(res.data.message);
          if (res.data.role === "user") {
            navigate(`/dashboard`);
            window.location.reload();
          } else if (res.data.role === "admin") {
            navigate(`/dashboard`);
            window.location.reload();
          } else {
            navigate(`/`);
          }
        } else if (res.data.status === 401) {
          toast.error(res.data.message);
          setDisabled(false);
          // swal("Warning", res.data.message, "warning");
        } else {
          setLogin({ ...loginInput, error_list: res.data.validation_errors });
          setDisabled(false);
        }
      });
    });
  };

  return (
    <IllustrationLayout
      title="Sign In"
      description="Enter your email and password to sign in"
      illustration={bgImage}
    >
      <MDBox component="form" role="form" onSubmit={loginSubmit}>
        <MDBox mb={2}>
          <MDInput
            type="email"
            label="Email"
            name="email"
            fullWidth
            onChange={handleInput}
            value={loginInput.email}
          />
          <Typography variant="caption" color="error" mt={1} display="block">
            {loginInput.error_list.email}
          </Typography>
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            type="password"
            label="Password"
            name="password"
            fullWidth
            onChange={handleInput}
            value={loginInput.password}
          />
          <Typography variant="caption" color="error" mt={1} display="block">
            {loginInput.error_list.password}
          </Typography>
        </MDBox>
        <MDBox display="flex" alignItems="center" ml={-1}>
          <Switch checked={rememberMe} onChange={handleSetRememberMe} />
          <MDTypography
            variant="button"
            fontWeight="regular"
            color="text"
            onClick={handleSetRememberMe}
            sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
          >
            &nbsp;&nbsp;Remember me
          </MDTypography>
        </MDBox>
        <MDBox mt={4} mb={1}>
          <MDButton
            type="submit"
            variant="gradient"
            color="dark"
            size="large"
            fullWidth
            disabled={disabled}
          >
            {disabled ? "Please Wait" : "sign in"}
          </MDButton>
        </MDBox>
        <MDBox mt={3} textAlign="center">
          <MDTypography variant="button" color="text">
            Don&apos;t remember your password?{" "}
            <MDTypography
              component={Link}
              to="/reset-password"
              variant="button"
              color="info"
              fontWeight="medium"
              textGradient
            >
              Reset
            </MDTypography>
          </MDTypography>
        </MDBox>
      </MDBox>
    </IllustrationLayout>
  );
}

export default login;
