// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";

// MUI
import { useState, useEffect } from "react";
import DataTable from "examples/Tables/DataTable";
import MDButton from "components/MDButton";

// @mui material components
import Icon from "@mui/material/Icon";
import axios from "axios";

// Skeleton
import TableSkeleton from "examples/Skeleton/table";

import swal from "sweetalert";
import toast from "react-hot-toast";
import DataDrawer from "./drawer";

function Manufacturer() {
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(1);
  const [dataList, setDataList] = useState([]);

  const [openDrawer, setOpenDrawer] = useState(false);
  // const [closeDrawer] = useState(anchor, false);
  const [issetData, setIssetData] = useState(false);
  const [dataRecord, setDataRecord] = useState();

  useEffect(() => {
    setDataRecord();
    setIssetData(false);
    axios.get(`/api/manufacturer`).then((res) => {
      if (res.data.status === 200) {
        setDataList(res.data.manufacturer);
        setRefresh(1);
      }
      setLoading(false);
    });
  }, [refresh]);

  if (loading) {
    return <TableSkeleton />;
  }

  const openAddDrawer = () => {
    setOpenDrawer(true);
    //   setUserRecordForEdit(user);
  };

  const openEditDrawer = (e, productId) => {
    e.preventDefault();

    setIssetData(true);
    setDataRecord(productId);
    setOpenDrawer(true);
  };

  const deleteData = (e, id) => {
    e.preventDefault();

    const thisClicked = e.currentTarget;
    thisClicked.setAttribute("disabled", "disabled");

    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this  data!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        axios.delete(`/api/manufacturer-delete/${id}`).then((res) => {
          if (res.data.status === 200) {
            toast.success(res.data.message);
            setRefresh(2);
          } else if (res.data.status === 404) {
            toast.error(res.data.message);
          }
        });
      } else {
        swal({
          title: "Data is safe!",
          text: "Once deleted, you will not be able to recover this data!",
          icon: "info",
        });
      }
    });
    thisClicked.removeAttribute("disabled");
  };

  return (
    <>
      <MDBox my={3}>
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-end" mb={2}>
          <MDButton variant="gradient" color="info" onClick={openAddDrawer}>
            <Icon>add_circle</Icon>
            &nbsp; add
          </MDButton>
          <MDBox display="flex">
            <MDBox ml={1}>&nbsp;</MDBox>
          </MDBox>
        </MDBox>
        <Card>
          <DataTable
            // canSearch
            isSorted={false}
            pagination={{ variant: "gradient", color: "light", size: "small" }}
            table={{
              columns: [
                { Header: "name", accessor: "name" },
                { Header: "gst no", accessor: "gst" },
                { Header: "phone no", accessor: "phone" },
                { Header: "action", accessor: "action", align: "right" },
              ],
              rows: dataList.map((row) => ({
                name: row.manufacturer_name,
                gst: row.manufacturer_gst_no,
                phone: row.manufacturer_phone_no,
                action: (
                  <>
                    <MDButton
                      variant="text"
                      onClick={(e) => openEditDrawer(e, row.id)}
                      circular
                      color="warning"
                      iconOnly
                    >
                      <Icon>edit</Icon>
                    </MDButton>
                    <MDButton
                      style={{ marginLeft: "10px" }}
                      variant="text"
                      circular
                      color="error"
                      iconOnly
                      onClick={(e) => deleteData(e, row.id)}
                    >
                      <Icon>delete</Icon>
                    </MDButton>
                  </>
                ),
              })),
            }}
          />
        </Card>
        {/* <ProductDrawer /> */}
      </MDBox>
      <DataDrawer
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer}
        setRefresh={setRefresh}
        dataRecord={dataRecord}
        issetData={issetData}
        setIssetData={setIssetData}
      />
    </>
  );
}

export default Manufacturer;
