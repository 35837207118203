import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";

import { useState, useEffect } from "react";
import MDButton from "components/MDButton";
// import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import Divider from "@mui/material/Divider";

// import FormGroup from "@mui/material/FormGroup";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import Checkbox from "@mui/material/Checkbox";

import axios from "axios";
import toast from "react-hot-toast";
// import dateFormat from "dateformat";

import ProductSkeleton from "examples/Skeleton/product";
// import imagePlaceholder from "assets/images/image_placeholder.jpeg";

function EditBanner(props) {
  const { setOpenDrawer, setRefresh, dataRecord, setIssetData } = props;
  const [validateError, setError] = useState([]);
  const [loading, setLoading] = useState(true);
  const [disabled, setDisabled] = useState(false);

  // const [controller] = useMaterialUIController();
  // const { darkMode } = controller;

  const [inputData, setInputData] = useState([]);
  const [inputPicture, setInputPicture] = useState([]);
  const [preview, setPreview] = useState();

  useEffect(() => {
    axios.get(`/api/banner-edit/${dataRecord}`).then((res) => {
      if (res.data.status === 200) {
        setInputData(res.data.banner);
      } else {
        setOpenDrawer(false);
        toast.error(res.data.message);
      }
      setLoading(false);
      setDisabled(false);
    });
  }, []);

  const handleInput = (e) => {
    e.persist();
    setInputData({ ...inputData, [e.target.name]: e.target.value });
  };

  const handleImage = (e) => {
    e.persist();
    setInputPicture({ image: e.target.files[0] });

    const objectUrl = URL.createObjectURL(e.target.files[0]);
    setPreview(objectUrl);
  };

  const handleSelect = (event) => {
    setInputData({ ...inputData, [event.target.name]: event.target.value });
  };

  const handClose = () => {
    setOpenDrawer(false);
    setIssetData(false);
  };

  const updateForm = (e) => {
    e.preventDefault();

    setDisabled(true);

    const formData = new FormData();
    formData.append("type", inputData.type ?? "");
    formData.append("off_percentage", inputData.off_percentage ?? "");
    formData.append("title", inputData.title ?? "");
    formData.append("sub_title", inputData.sub_title ?? "");
    formData.append("describtion", inputData.describtion ?? "");
    formData.append("link", inputData.link ?? "");
    if (preview) {
      formData.append("image", inputPicture.image);
    }

    axios.post(`/api/banner-update/${dataRecord}`, formData).then((res) => {
      setError([]);
      if (res.data.status === 200) {
        toast.success(res.data.message);
        setOpenDrawer(false);
        setRefresh(2);
        setIssetData(false);
      } else if (res.data.status === 202) {
        toast.error(res.data.message);
      } else if (res.data.status === 422) {
        setError(res.data.errors);
      } else if (res.data.status === 404) {
        toast.error(res.data.message);
      }
      setDisabled(false);
    });
  };

  return (
    <div>
      {loading ? (
        <ProductSkeleton />
      ) : (
        <MDBox component="form" onSubmit={updateForm}>
          <Grid container alignItems="center">
            <Grid item xs={12} md={6} p={1}>
              <TextField
                fullWidth
                helperText={validateError.type ? validateError.type : " "}
                error={!!validateError.type}
                label="Type"
                name="type"
                defaultValue={inputData.type}
                onChange={handleSelect}
                variant="standard"
                style={{ paddingTop: "8px" }}
                select
                InputProps={{
                  readOnly: true,
                }}
              >
                <MenuItem value="a1">Top Left (A1)</MenuItem>
                <MenuItem value="a2">Top Right 1 (A2)</MenuItem>
                <MenuItem value="a3">Top Right 2 (A3)</MenuItem>
                <Divider sx={{ my: 0.5 }} />
                <MenuItem value="b1">Banner 1 (B1)</MenuItem>
                <MenuItem value="b2">Banner 2 (B2)</MenuItem>
                <MenuItem value="b3">Banner 3 (B3)</MenuItem>
                <MenuItem value="b4">Banner 4 (B4)</MenuItem>
                <Divider sx={{ my: 0.5 }} />
                <MenuItem value="c1">Bottom 1 (C1)</MenuItem>
                <MenuItem value="c2">Banner 2 (C2)</MenuItem>
              </TextField>
            </Grid>

            <Grid item xs={12} md={6} p={1}>
              <TextField
                fullWidth
                helperText={validateError.off_percentage ? validateError.off_percentage : " "}
                error={!!validateError.off_percentage}
                label="Offer Percentage(%)"
                type="number"
                name="off_percentage"
                onChange={handleInput}
                defaultValue={inputData.off_percentage}
                variant="standard"
              />
            </Grid>
            <Grid item xs={12} p={1}>
              <TextField
                fullWidth
                helperText={validateError.title ? validateError.title : " "}
                error={!!validateError.title}
                label="Title"
                type="text"
                name="title"
                onChange={handleInput}
                defaultValue={inputData.title}
                variant="standard"
              />
            </Grid>
            <Grid item xs={12} p={1}>
              <TextField
                fullWidth
                helperText={validateError.sub_title ? validateError.sub_title : " "}
                error={!!validateError.sub_title}
                label="Sub Title"
                type="text"
                name="sub_title"
                onChange={handleInput}
                defaultValue={inputData.sub_title}
                variant="standard"
              />
            </Grid>
            <Grid item xs={12} p={1}>
              <TextField
                fullWidth
                helperText={validateError.describtion ? validateError.describtion : " "}
                error={!!validateError.describtion}
                label="Describtion"
                type="text"
                name="describtion"
                onChange={handleInput}
                defaultValue={inputData.describtion}
                variant="standard"
              />
            </Grid>
            <Grid item xs={12} p={1}>
              <TextField
                fullWidth
                helperText={validateError.link ? validateError.link : " "}
                error={!!validateError.link}
                label="Link (url)"
                type="text"
                name="link"
                onChange={handleInput}
                defaultValue={inputData.link}
                variant="standard"
              />
            </Grid>
            <Grid item xs={12} pt={2}>
              <MDBox
                component="img"
                src={`${process.env.REACT_APP_API_URL}/uploads/banner/thumb/${inputData.type}.png`}
                alt="pic"
                sx={{
                  objectFit: "cover",
                  width: "100%",
                  height: "auto",
                  borderRadius: "5px",
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} py={3} pr={2}>
              {preview ? (
                <MDBox
                  component="img"
                  src={preview}
                  alt="pic"
                  sx={{
                    objectFit: "cover",
                    width: "100%",
                    height: "200px",
                    borderRadius: "5px",
                  }}
                />
              ) : (
                <MDBox
                  component="img"
                  src={`${process.env.REACT_APP_API_URL}/${inputData.image}`}
                  alt="pic"
                  sx={{
                    objectFit: "cover",
                    width: "100%",
                    height: "200px",
                    borderRadius: "5px",
                  }}
                />
              )}
            </Grid>
            <Grid item xs={12} md={6} py={3}>
              <TextField
                fullWidth
                helperText={validateError.image ? validateError.image : " "}
                error={!!validateError.image}
                label="Image (type: image/*)"
                type="file"
                inputProps={{
                  accept: "image/*",
                }}
                name="image"
                multiple
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={handleImage}
                variant="standard"
                sx={{ borderBottom: "none" }}
              />
            </Grid>
          </Grid>

          <Divider />

          <MDBox
            // sx={{
            //   display: "flex",
            //   alignContent: "flex-end",
            // }}
            mb={2}
          >
            <Grid item md={2} mt={4} mb={2}>
              <MDButton
                size="small"
                color="dark"
                variant="gradient"
                type="submit"
                disabled={disabled}
              >
                <Icon>done</Icon>&nbsp;
                {disabled ? "Please Wait" : "Save"}
              </MDButton>
              &nbsp;
              <MDButton size="small" color="light" variant="gradient" onClick={handClose}>
                <Icon>close</Icon>&nbsp; Close
              </MDButton>
            </Grid>
          </MDBox>
        </MDBox>
      )}
    </div>
  );
}

export default EditBanner;
