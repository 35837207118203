/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// skelton
import MDBox from "components/MDBox";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";

function TableSkeleton() {
  return (
    <MDBox mt={4} pt={6} pb={3}>
      <Card>
        <MDBox p={3}>
          <Typography component="div" variant="h1">
            <Skeleton />
          </Typography>
          <MDBox pt={3}>
            <Skeleton
              width="100%"
              style={{ borderRadius: "5px" }}
              height={300}
              variant="rectangular"
            />
          </MDBox>
        </MDBox>
      </Card>
    </MDBox>
  );
}

export default TableSkeleton;
