// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import DataTable from "examples/Tables/DataTable";
import MDButton from "components/MDButton";
import MDBadge from "components/MDBadge";

// MUI
import { useState, useEffect } from "react";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import Switch from "@mui/material/Switch";

import axios from "axios";
// import dateFormat from "dateformat";

// Skeleton
import TableSkeleton from "examples/Skeleton/table";

import Link from "@mui/material/Link";
import swal from "sweetalert";
import toast from "react-hot-toast";
import DataDrawer from "./drawer";
import Filter from "./form/filter";

function Products() {
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(1);
  const [dataList, setDataList] = useState([]);

  const [openDrawer, setOpenDrawer] = useState(false);
  // const [closeDrawer] = useState(anchor, false);
  const [issetData, setIssetData] = useState(false);
  const [dataRecord, setDataRecord] = useState();
  const [openFilter, setOpenFilter] = useState(false);

  useEffect(() => {
    setDataRecord();
    setIssetData(false);
    axios.get(`/api/product`).then((res) => {
      if (res.data.status === 200) {
        setDataList(res.data.products);
        setRefresh(1);
      }
      setLoading(false);
    });
  }, [refresh]);

  if (loading) {
    return <TableSkeleton />;
  }

  const openAddDrawer = () => {
    setOpenDrawer(true);
    //   setUserRecordForEdit(user);
  };

  const openFilterModal = (e) => {
    e.preventDefault();

    setOpenFilter(true);
  };

  const handleStatus = (event, id) => {
    const data = {
      status: event.target.checked,
    };

    axios.put(`/api/product-update-status/${id}`, data).then((res) => {
      if (res.data.status === 200) {
        toast.success(res.data.message);
        // setRefresh(2);
      } else {
        toast.error(res.data.message);
      }
    });
  };

  const openEditDrawer = (e, productId) => {
    e.preventDefault();

    setIssetData(true);
    setDataRecord(productId);
    setOpenDrawer(true);
  };

  const deleteData = (e, id) => {
    e.preventDefault();

    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this  data!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        axios.delete(`/api/product-delete/${id}`).then((res) => {
          if (res.data.status === 200) {
            toast.success(res.data.message);
            setRefresh(2);
          } else if (res.data.status === 404) {
            toast.error(res.data.message);
          }
        });
      } else {
        swal({
          title: "Data is safe!",
          text: "Once deleted, you will not be able to recover this data!",
          icon: "info",
          button: false,
          timer: 1000,
        });
      }
    });
  };

  return (
    <>
      <MDBox my={3}>
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
          <MDButton variant="gradient" color="info" onClick={openAddDrawer}>
            <Icon>add_circle</Icon>
            &nbsp; add
          </MDButton>
          <MDBox display="flex">
            <MDBox>
              <MDButton variant="outlined" color="dark" onClick={openFilterModal}>
                filters&nbsp;
                <Icon>keyboard_arrow_down</Icon>
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
        <Card>
          <DataTable
            canSearch
            // isSorted={false}
            pagination={{ variant: "gradient", color: "light", size: "small" }}
            table={{
              columns: [
                { Header: "category", accessor: "category" },
                { Header: "name", accessor: "name" },
                { Header: "image", accessor: "image" },
                { Header: "price", accessor: "price" },
                {
                  Header: "discount",
                  accessor: "discount",
                  align: "center",
                  Cell: ({ value }) => {
                    let status;
                    if (value) {
                      status = value;
                    } else {
                      status = <MDTypography variant="inherit">-</MDTypography>;
                    }
                    return status;
                  },
                },
                { Header: "status", accessor: "status", disableSortBy: true },
                { Header: "action", accessor: "action", align: "right", disableSortBy: true },
              ],
              rows: dataList.map((row) => ({
                category: <MDBadge badgeContent={row.category.name} container color="secondary" />,
                name: row.name,
                price: (
                  <MDTypography variant="inherit">
                    &pound;{row.current_price ? row.current_price : row.price}
                  </MDTypography>
                ),
                image: (
                  <MDBox
                    component="img"
                    src={`${process.env.REACT_APP_API_URL}/${row.image}`}
                    alt="pic"
                    sx={{
                      // boxShadow: 15,
                      // border: "1px solid #d2d6da",
                      objectFit: "contain",
                      width: "70px",
                      height: "50px",
                      borderRadius: "5px",
                    }}
                  />
                ),
                status: (
                  <Switch
                    defaultChecked={row.status === 1}
                    onChange={(event) => handleStatus(event, row.id)}
                  />
                ),
                // eslint-disable-next-line no-nested-ternary
                discount: row.discount ? (
                  row.discount_type === "price" ? (
                    <MDTypography variant="inherit">&pound;{row.discount}</MDTypography>
                  ) : (
                    <MDTypography variant="inherit">{row.discount}%</MDTypography>
                  )
                ) : null,
                action: (
                  <>
                    <Tooltip title="View Product" arrow>
                      <Link
                        href={`${process.env.REACT_APP_API_URL}/product/${row.slug}`}
                        target="_blank"
                      >
                        <MDButton variant="text" circular color="info" iconOnly>
                          <Icon>open_in_new</Icon>
                        </MDButton>
                      </Link>
                    </Tooltip>
                    &nbsp;
                    <Tooltip title="Edit Product" arrow>
                      <MDButton
                        variant="text"
                        onClick={(e) => openEditDrawer(e, row.id)}
                        circular
                        color="warning"
                        iconOnly
                      >
                        <Icon>edit</Icon>
                      </MDButton>
                    </Tooltip>
                    &nbsp;
                    <Tooltip title="Delete Product" arrow>
                      <MDButton
                        variant="text"
                        circular
                        color="error"
                        iconOnly
                        onClick={(e) => deleteData(e, row.id)}
                      >
                        <Icon>delete</Icon>
                      </MDButton>
                    </Tooltip>
                  </>
                ),
              })),
            }}
          />
        </Card>
        {/* <ProductDrawer /> */}
      </MDBox>
      <DataDrawer
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer}
        setRefresh={setRefresh}
        dataRecord={dataRecord}
        issetData={issetData}
        setIssetData={setIssetData}
      />
      <Filter openFilter={openFilter} setOpenFilter={setOpenFilter} setDataList={setDataList} />
    </>
  );
}

export default Products;
