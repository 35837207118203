// import * as React from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";

import { useState, useEffect } from "react";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
// import MDInput from "components/MDInput";

import Divider from "@mui/material/Divider";
// import Icon from "@mui/material/Icon";

import axios from "axios";
import toast from "react-hot-toast";
// import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React contexts
// import { useMaterialUIController } from "context";

import ProductSkeleton from "examples/Skeleton/product";

function CreateOrder(props) {
  const { setOpenDrawer, setRefresh, setIssetData } = props;
  const [validateError, setError] = useState([]);
  const [loading, setLoading] = useState(true);
  const [categoryList, setCategoryList] = useState([]);
  const [disabled, setDisabled] = useState(false);

  // const [controller] = useMaterialUIController();
  // const { darkMode } = controller;

  const [inputData, setInputData] = useState({
    category_id: "",
    name: "",
    short_description: "",
    long_description: "",
  });

  useEffect(() => {
    // setLoading(false);
    setDisabled(false);
    axios.get(`/api/order-create`).then((res) => {
      if (res.data.status === 200) {
        setCategoryList(res.data.category);
      }
      setLoading(false);
    });
  }, []);

  const handleInput = (e) => {
    e.persist();
    setInputData({ ...inputData, [e.target.name]: e.target.value });
  };

  const handleSelect = (event) => {
    setInputData({ ...inputData, [event.target.name]: event.target.value });
  };

  const handClose = () => {
    setOpenDrawer(false);
    setIssetData(false);
  };

  const createForm = (e) => {
    e.preventDefault();

    setDisabled(true);

    const data = {
      category_id: inputData.category_id,
      name: inputData.name,
      short_description: inputData.short_description,
      long_description: inputData.long_description,
    };

    axios.post(`/api/order-store`, data).then((res) => {
      setError([]);
      if (res.data.status === 200) {
        toast.success(res.data.message);
        setOpenDrawer(false);
        setRefresh(2);
        setIssetData(false);
      } else if (res.data.status === 202) {
        toast.error(res.data.message);
      } else if (res.data.status === 422) {
        setError(res.data.errors);
      } else if (res.data.status === 404) {
        toast.error(res.data.message);
      }
      setDisabled(false);
    });
  };

  return (
    <div>
      {loading ? (
        <ProductSkeleton />
      ) : (
        <MDBox component="form" role="form" onSubmit={createForm}>
          <Grid container alignItems="center">
            <Grid item xs={12} md={6} p={1}>
              <TextField
                fullWidth
                helperText={validateError.category_id ? validateError.category_id : " "}
                error={!!validateError.category_id}
                label="Category"
                name="category_id"
                defaultValue={inputData.category_id}
                onChange={handleSelect}
                variant="standard"
                style={{ paddingTop: "8px" }}
                select
              >
                {categoryList.map((data) => (
                  <MenuItem value={data.id}>{data.name}</MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12} md={6} p={1}>
              <TextField
                fullWidth
                helperText={validateError.name ? validateError.name : " "}
                error={!!validateError.name}
                label="Name"
                type="text"
                name="name"
                onChange={handleInput}
                defaultValue={inputData.name}
                variant="standard"
              />
            </Grid>
            <Grid item xs={12} p={1}>
              <TextField
                fullWidth
                multiline
                rows={3}
                helperText={validateError.short_description ? validateError.short_description : " "}
                error={!!validateError.short_description}
                label="Short Description"
                type="text"
                name="short_description"
                onChange={handleInput}
                defaultValue={inputData.short_description}
                variant="standard"
              />
            </Grid>
          </Grid>

          {/* {productFields.map((input, index) => ( */}
          <MDBox>
            <Grid container>
              <Grid item md={12} p={1}>
                <MDBox display="flex" justifyContent="space-between" alignItems="flex-end">
                  <MDTypography variant="caption" fontWeight="medium">
                    {/* Product - {index + 1} */}
                  </MDTypography>
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
          {/* ))} */}

          <Divider />

          <MDBox
            // sx={{
            //   display: "flex",
            //   alignContent: "flex-end",
            // }}
            mb={2}
          >
            <MDButton
              size="small"
              color="dark"
              variant="gradient"
              type="submit"
              disabled={disabled}
            >
              {disabled ? "Please Wait" : "Submit"}
            </MDButton>
            &nbsp;
            <MDButton size="small" color="light" variant="gradient" onClick={handClose}>
              Close
            </MDButton>
          </MDBox>
        </MDBox>
      )}
    </div>
  );
}

export default CreateOrder;
