import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

import { useState, useEffect } from "react";

import DataTable from "examples/Tables/DataTable";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDEditor from "components/MDEditor";
import MDDropzone from "components/MDDropzone";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";

import axios from "axios";
import toast from "react-hot-toast";
import swal from "sweetalert";
import dateFormat from "dateformat";

// import Link from "@mui/material/Link";

import ProductSkeleton from "examples/Skeleton/product";
// import imagePlaceholder from "assets/images/image_placeholder.jpeg";

function EditProduct(props) {
  const { setOpenDrawer, setRefresh, dataRecord, setIssetData } = props;
  const [validateError, setError] = useState([]);
  const [loading, setLoading] = useState(true);
  const [disabled, setDisabled] = useState(false);

  // const [controller] = useMaterialUIController();
  // const { darkMode } = controller;

  const [productGallery, setProductGallery] = useState([]);
  const [productGalleryRefresh, setProductGalleryRefresh] = useState(false);

  const [inputPicture, setInputPicture] = useState([]);
  const [editorValue, setEditorValue] = useState();
  const [preview, setPreview] = useState();
  const [inputData, setInputData] = useState({
    name: "",
  });
  const [categoryList, setCategoryList] = useState([]);

  useEffect(() => {
    axios.get(`/api/product-edit/${dataRecord}`).then((res) => {
      if (res.data.status === 200) {
        setInputData(res.data.product);
        setProductGallery(res.data.product_gallery);
        setEditorValue(res.data.product.long_description);
        setCategoryList(res.data.category);
      } else {
        setOpenDrawer(false);
        toast.error(res.data.message);
      }
      setLoading(false);
      setDisabled(false);
    });
  }, []);

  useEffect(() => {
    axios.get(`/api/product-gallery/${dataRecord}`).then((res) => {
      if (res.data.status === 200) {
        setProductGallery(res.data.product_gallery);
      } else {
        setOpenDrawer(false);
        toast.error(res.data.message);
      }
    });
  }, [productGalleryRefresh]);

  const handleInput = (e) => {
    e.persist();
    setInputData({ ...inputData, [e.target.name]: e.target.value });
  };

  const handleImage = (e) => {
    e.persist();
    setInputPicture({ image: e.target.files[0] });

    const objectUrl = URL.createObjectURL(e.target.files[0]);
    setPreview(objectUrl);
  };

  const handleSelect = (event) => {
    setInputData({ ...inputData, [event.target.name]: event.target.value });
  };

  const handleCheckbox = (event) => {
    setInputData({ ...inputData, [event.target.name]: event.target.checked });
  };

  const handClose = () => {
    setOpenDrawer(false);
    setIssetData(false);
  };

  const updateForm = (e) => {
    e.preventDefault();

    setDisabled(true);

    const formData = new FormData();
    formData.append("category_id", inputData.category_id ?? "");
    formData.append("name", inputData.name);
    formData.append("short_description", inputData.short_description ?? "");
    formData.append("long_description", editorValue ?? "");
    if (preview) {
      formData.append("image", inputPicture.image);
    }
    formData.append("price", inputData.price ?? "");
    formData.append("size", inputData.size ?? "");
    formData.append("discount", inputData.discount ?? "");
    formData.append("discount_type", inputData.discount_type ?? "");
    formData.append("stock", inputData.stock ?? "");
    formData.append("max_qty", inputData.max_qty ?? "");
    formData.append("meta_keyword", inputData.meta_keyword ?? "");
    formData.append("tag_trending", inputData.tag_trending ? 1 : "");
    formData.append("tag_new_arrival", inputData.tag_new_arrival ? 1 : "");
    formData.append("tag_top_save", inputData.tag_top_save ? 1 : "");
    formData.append("tag_best_selling", inputData.tag_best_selling ? 1 : "");

    axios.post(`/api/product-update/${dataRecord}`, formData).then((res) => {
      setError([]);
      if (res.data.status === 200) {
        toast.success(res.data.message);
        setOpenDrawer(false);
        setRefresh(2);
        setIssetData(false);
      } else if (res.data.status === 202) {
        toast.error(res.data.message);
      } else if (res.data.status === 422) {
        toast.error("Please check the fields");
        setError(res.data.errors);
      } else if (res.data.status === 404) {
        toast.error(res.data.message);
      }
      setDisabled(false);
    });
  };

  const deleteFile = (e, id) => {
    e.preventDefault();

    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this  data!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        axios.delete(`/api/product-gallery-delete/${id}`).then((res) => {
          if (res.data.status === 200) {
            toast.success(res.data.message);
            setProductGalleryRefresh(true);
          } else if (res.data.status === 404) {
            toast.error(res.data.message);
          }
        });
      } else {
        swal({
          title: "Data is safe!",
          text: "Once deleted, you will not be able to recover this data!",
          icon: "info",
        });
      }
    });
  };

  return (
    <div>
      {loading ? (
        <ProductSkeleton />
      ) : (
        <MDBox component="form" onSubmit={updateForm}>
          <Grid container alignItems="center">
            <Grid item xs={12} p={1}>
              <TextField
                fullWidth
                helperText={validateError.name ? validateError.name : " "}
                error={!!validateError.name}
                label="Name"
                type="text"
                name="name"
                onChange={handleInput}
                defaultValue={inputData.name}
                variant="standard"
              />
            </Grid>
            <Grid item xs={12} p={1}>
              <TextField
                fullWidth
                multiline
                rows={3}
                helperText={validateError.short_description ? validateError.short_description : " "}
                error={!!validateError.short_description}
                label="Short Description"
                type="text"
                name="short_description"
                onChange={handleInput}
                defaultValue={inputData.short_description}
                variant="standard"
              />
            </Grid>
            <Grid item xs={12} md={4} p={1}>
              <TextField
                fullWidth
                helperText={validateError.price ? validateError.price : " "}
                error={!!validateError.price}
                label="Price"
                type="text"
                name="price"
                onChange={handleInput}
                defaultValue={inputData.price}
                variant="standard"
              />
            </Grid>
            <Grid item xs={12} md={4} p={1}>
              <TextField
                fullWidth
                helperText={validateError.size ? validateError.size : " "}
                error={!!validateError.size}
                label="Size (with Unit)"
                type="text"
                name="size"
                onChange={handleInput}
                defaultValue={inputData.size}
                variant="standard"
              />
            </Grid>
            <Grid item xs={12} md={4} p={1}>
              <TextField
                fullWidth
                helperText={validateError.category_id ? validateError.category_id : " "}
                error={!!validateError.category_id}
                label="Category"
                name="category_id"
                defaultValue={inputData.category_id}
                onChange={handleSelect}
                variant="standard"
                style={{ paddingTop: "8px" }}
                select
              >
                {categoryList.map((data) => (
                  <MenuItem value={data.id}>{data.name}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={3} p={1}>
              <TextField
                fullWidth
                helperText={validateError.discount ? validateError.discount : " "}
                error={!!validateError.discount}
                label="Discount"
                type="number"
                name="discount"
                onChange={handleInput}
                defaultValue={inputData.discount}
                variant="standard"
              />
            </Grid>
            <Grid item xs={12} md={3} p={1}>
              <TextField
                fullWidth
                helperText={validateError.discount_type ? validateError.discount_type : " "}
                error={!!validateError.discount_type}
                label="Discount Type"
                name="discount_type"
                defaultValue={inputData.discount_type}
                onChange={handleSelect}
                variant="standard"
                style={{ paddingTop: "8px" }}
                select
              >
                <MenuItem value="price">Price</MenuItem>
                <MenuItem value="percentage">Percentage</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={6} md={3} p={1}>
              <TextField
                fullWidth
                helperText={validateError.stock ? validateError.stock : " "}
                error={!!validateError.stock}
                label="Stock"
                type="number"
                name="stock"
                onChange={handleInput}
                defaultValue={inputData.stock}
                variant="standard"
              />
            </Grid>
            <Grid item xs={6} md={3} p={1}>
              <TextField
                fullWidth
                helperText={validateError.max_qty ? validateError.max_qty : " "}
                error={!!validateError.max_qty}
                label="Max Qty"
                type="number"
                name="max_qty"
                onChange={handleInput}
                defaultValue={inputData.max_qty}
                variant="standard"
              />
            </Grid>
            <Grid item xs={12} p={1}>
              <TextField
                fullWidth
                helperText={validateError.meta_keyword ? validateError.meta_keyword : " "}
                error={!!validateError.meta_keyword}
                label="Keywords"
                type="text"
                name="meta_keyword"
                onChange={handleInput}
                defaultValue={inputData.meta_keyword}
                variant="standard"
              />
            </Grid>
            <Grid item xs={12} md={4} p={1}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked={inputData.tag_trending}
                      name="tag_trending"
                      onChange={handleCheckbox}
                    />
                  }
                  label="Trending Product"
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={4} p={1}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked={inputData.tag_new_arrival}
                      name="tag_new_arrival"
                      onChange={handleCheckbox}
                    />
                  }
                  label="New Arrival"
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={4} p={1}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked={inputData.tag_top_save}
                      name="tag_top_save"
                      onChange={handleCheckbox}
                    />
                  }
                  label="Top Save Today"
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={4} p={1}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked={inputData.tag_best_selling}
                      name="tag_best_selling"
                      onChange={handleCheckbox}
                    />
                  }
                  label="Best Selling"
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} pb={9}>
              <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                  Description&nbsp;&nbsp;
                  <MDTypography variant="caption" fontWeight="regular" color="text">
                    &nbsp;
                  </MDTypography>
                </MDTypography>
              </MDBox>
              <MDEditor
                value={editorValue}
                onChange={setEditorValue}
                name="long_description"
                style={{ height: "18rem" }}
              />
            </Grid>
            <Grid item xs={12} md={6} py={3} pr={2}>
              {preview ? (
                <MDBox
                  component="img"
                  src={preview}
                  alt="pic"
                  shadow="sm"
                  sx={{
                    border: "1px solid #d2d6da",
                    objectFit: "contain",
                    width: "100%",
                    height: "200px",
                    borderRadius: "5px",
                  }}
                />
              ) : (
                <MDBox
                  component="img"
                  src={`${process.env.REACT_APP_API_URL}/${inputData.image}`}
                  alt="pic"
                  // shadow="sm"
                  sx={{
                    objectFit: "contain",
                    width: "100%",
                    height: "200px",
                    borderRadius: "5px",
                  }}
                />
              )}
            </Grid>
            <Grid item xs={12} md={6} py={3}>
              <TextField
                fullWidth
                helperText={validateError.image ? validateError.image : " "}
                error={!!validateError.image}
                label="Image (type: image/png, ratio: 1*1)"
                type="file"
                accept="image/*"
                name="image"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  accept: "image/*",
                  style: {
                    opacity: "0.9",
                  },
                }}
                onChange={handleImage}
                variant="standard"
                sx={{ borderBottom: "none" }}
              />
            </Grid>
            <Grid item xs={12} p={1}>
              <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                  Product Images&nbsp;&nbsp;
                  <MDTypography variant="caption" fontWeight="regular" color="text">
                    (type: mimes:jpg,jpeg & multiple)
                  </MDTypography>
                </MDTypography>
              </MDBox>
              <MDDropzone
                options={{
                  // addRemoveLinks: true,
                  acceptedFiles: "image/jpg, image/jpeg",
                  dictDefaultMessage: "Drop images here to upload or Click here to upload",
                  maxFilesize: "800",
                  // withCredentials: true,
                  // headers: {
                  //   "Content-Type": "application/json",
                  //   Accept: "application/json",
                  //   _token: `Bearer ${localStorage.getItem("auth_token")}`,
                  // },
                  // headers: { _token: localStorage.getItem("auth_token") },
                  // headers: { _token: `Bearer ${localStorage.getItem("auth_token")}` },
                  url: `${process.env.REACT_APP_API_URL}/api/product/fileupload/gallery/${dataRecord}`,
                }}
              />
            </Grid>
            {productGallery && (
              <Grid item xs={12} p={1}>
                <MDBox>
                  <DataTable
                    isSorted={false}
                    pagination={false}
                    showTotalEntries={false}
                    entriesPerPage={false}
                    noEndBorder
                    table={{
                      columns: [
                        { Header: "name", accessor: "name" },
                        { Header: "image", accessor: "image" },
                        { Header: "uploaded at", accessor: "created_at" },
                        { Header: "action", accessor: "action", align: "right" },
                      ],
                      rows: productGallery.map((file) => ({
                        name: file.name,
                        image: (
                          <MDBox
                            component="img"
                            src={`${process.env.REACT_APP_API_URL}/${file.path}`}
                            alt="pic"
                            sx={{
                              objectFit: "contain",
                              width: "70px",
                              height: "50px",
                              borderRadius: "5px",
                            }}
                          />
                        ),
                        created_at: dateFormat(file.created_at, "dd/mm/yyyy - hh:MM:ss TT"),
                        action: (
                          <Tooltip title="Delete Image" arrow>
                            <MDButton
                              variant="text"
                              circular
                              color="error"
                              iconOnly
                              onClick={(e) => deleteFile(e, file.id)}
                            >
                              <Icon>delete</Icon>
                            </MDButton>
                          </Tooltip>
                        ),
                      })),
                    }}
                  />
                </MDBox>
              </Grid>
            )}
          </Grid>

          <Divider />

          <MDBox
            // sx={{
            //   display: "flex",
            //   alignContent: "flex-end",
            // }}
            mb={2}
          >
            <MDButton
              size="small"
              color="dark"
              variant="gradient"
              type="submit"
              disabled={disabled}
            >
              {disabled ? "Please Wait" : "Update"}
            </MDButton>
            &nbsp;
            <MDButton size="small" color="light" variant="gradient" onClick={handClose}>
              Close
            </MDButton>
          </MDBox>
        </MDBox>
      )}
    </div>
  );
}

export default EditProduct;
