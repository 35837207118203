import Drawer from "@mui/material/Drawer";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

import MDTypography from "components/MDTypography";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

import { useTheme } from "@mui/material/styles";
import { useState, useEffect } from "react";

import { Document, Page } from "react-pdf/dist/esm/entry.webpack";

import axios from "axios";
import swal from "sweetalert";
import toast from "react-hot-toast";
import PdfSkeleton from "examples/Skeleton/pdf";

function PreviewDrawer(props) {
  const { openPreview, setOpenPreview, dataRecord, docType, setDocType, refreshPreview } = props;

  const [disabled, setDisabled] = useState(false);
  const [base64String, setBase64String] = useState([]);

  useEffect(() => {
    setDisabled(true);
    setBase64String([]);
    if (docType) {
      axios.get(`/api/order-${docType}/${dataRecord}`).then((res) => {
        if (res.data.status === 200) {
          setBase64String(res.data.pdf);
        } else {
          setOpenPreview(false);
          toast.error(res.data.message);
        }
        setDisabled(false);
      });
    }
  }, [refreshPreview]);

  const handleClosePreview = () => {
    setOpenPreview(false);
    setBase64String([]);
    setDocType(false);
  };

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  // eslint-disable-next-line no-shadow
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const previousPage = () => {
    setPageNumber(pageNumber - 1);
  };

  const nextPage = () => {
    setPageNumber(pageNumber + 1);
  };

  const previewLoading = () => <PdfSkeleton />;

  const downloadPDF = (e) => {
    e.preventDefault();
    const linkSource = `data:application/pdf;base64,${base64String}`;
    const downloadLink = document.createElement("a");
    const fileName = `${docType}.pdf`;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  const theme = useTheme();

  const mailNow = (e) => {
    e.preventDefault();

    swal({
      title: "Are you sure?",
      text: "Send mail, you will not be able to recover!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setDisabled(true);
        axios.get(`/api/mail-order-${docType}/${dataRecord}`).then((res) => {
          if (res.data.status === 200) {
            toast.success(res.data.message);
            setOpenPreview(false);
            setBase64String([]);
          } else {
            toast.error(res.data.message);
          }
          setDisabled(false);
        });
      } else {
        swal({
          text: "Mail not sent!",
          icon: "info",
          buttons: false,
          timer: 1000,
        });
      }
    });
  };

  return (
    <div>
      <Drawer
        hideBackdrop
        transitionDuration={{ enter: 450, exit: 300 }}
        anchor="right"
        open={openPreview}
        onClose={handleClosePreview}
        // onOpen={toggleDrawer(anchor, true)}
        style={{ borderRadius: 0 }}
        PaperProps={{
          sx: {
            width: 360,
            // width: "20rem",
            borderRadius: 0,
            margin: 0,
            height: "100vh",
            [theme.breakpoints.up("md")]: {
              width: 720,
            },
          },
        }}
      >
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="baseline"
          pt={4}
          pb={0.5}
          px={3}
        >
          <MDBox>
            <MDTypography variant="h5">{`Preview of - ${docType}`}</MDTypography>
            <MDTypography variant="body2" color="text">
              see your invoice and send
            </MDTypography>
          </MDBox>

          <Icon
            sx={{
              strokeWidth: "2px",
              cursor: "pointer",
              transform: "translateY(5px)",
            }}
            onClick={handleClosePreview}
          >
            close
          </Icon>
        </MDBox>

        <Divider />

        <MDBox pb={1} px={3} sx={{ textAlign: "center" }}>
          <Document
            file={`data:application/pdf;base64,${base64String}`}
            onLoadSuccess={onDocumentLoadSuccess}
            loading={previewLoading}
            externalLinkRel="_blank"
          >
            <Page pageNumber={pageNumber} />
          </Document>
          <MDTypography variant="h6">
            Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
          </MDTypography>
          <Tooltip title="Previous Page" arrow>
            <MDButton
              iconOnly
              color="info"
              size="small"
              disabled={pageNumber <= 1}
              onClick={previousPage}
            >
              <Icon>chevron_left</Icon>
            </MDButton>
          </Tooltip>
          &nbsp;
          <Tooltip title="Next Page" arrow>
            <MDButton
              iconOnly
              color="info"
              size="small"
              disabled={pageNumber >= numPages}
              onClick={nextPage}
            >
              <Icon>navigate_next</Icon>
            </MDButton>
          </Tooltip>
        </MDBox>
        <MDBox pt={2} pb={3} px={3}>
          <MDButton
            size="small"
            color="dark"
            variant="gradient"
            onClick={mailNow}
            disabled={disabled}
          >
            <Icon className="material-icons-outlined">send</Icon>&nbsp;
            {disabled ? "Please Wait" : "Send"}
          </MDButton>
          &nbsp;
          <MDButton
            size="small"
            color="dark"
            variant="gradient"
            onClick={downloadPDF}
            disabled={disabled}
          >
            <Icon>download</Icon>&nbsp;
            {disabled ? "Please Wait" : "Download"}
          </MDButton>
          &nbsp;
          <MDButton size="small" color="light" variant="gradient" onClick={handleClosePreview}>
            <Icon>close</Icon>&nbsp; Close
          </MDButton>
        </MDBox>
      </Drawer>
    </div>
  );
}

export default PreviewDrawer;
