import TextField from "@mui/material/TextField";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";

import { useState, useEffect } from "react";
import MDButton from "components/MDButton";
// import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import Divider from "@mui/material/Divider";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import axios from "axios";
import toast from "react-hot-toast";
// import dateFormat from "dateformat";

import ProductSkeleton from "examples/Skeleton/product";
// import imagePlaceholder from "assets/images/image_placeholder.jpeg";

function EditNewsletter(props) {
  const { setOpenDrawer, setRefresh, dataRecord, setIssetData } = props;
  const [validateError, setError] = useState([]);
  const [loading, setLoading] = useState(true);
  const [disabled, setDisabled] = useState(false);

  // const [controller] = useMaterialUIController();
  // const { darkMode } = controller;

  const [inputData, setInputData] = useState([]);

  useEffect(() => {
    axios.get(`/api/newsletter-edit/${dataRecord}`).then((res) => {
      if (res.data.status === 200) {
        setInputData(res.data.newsletter);
      } else {
        setOpenDrawer(false);
        toast.error(res.data.message);
      }
      setLoading(false);
      setDisabled(false);
    });
  }, []);

  const handleInput = (e) => {
    e.persist();
    setInputData({ ...inputData, [e.target.name]: e.target.value });
  };

  const handleCheckbox = (event) => {
    setInputData({ ...inputData, [event.target.name]: event.target.checked });
  };

  const handClose = () => {
    setOpenDrawer(false);
    setIssetData(false);
  };

  const updateForm = (e) => {
    e.preventDefault();

    setDisabled(true);

    const data = {
      f_name: inputData.f_name,
      l_name: inputData.l_name,
      email: inputData.email,
      status: inputData.subscribe_status ? 1 : 0,
    };

    axios.put(`/api/newsletter-update/${dataRecord}`, data).then((res) => {
      setError([]);
      if (res.data.status === 200) {
        toast.success(res.data.message);
        setOpenDrawer(false);
        setRefresh(2);
        setIssetData(false);
      } else if (res.data.status === 202) {
        toast.error(res.data.message);
      } else if (res.data.status === 422) {
        setError(res.data.errors);
      } else if (res.data.status === 404) {
        toast.error(res.data.message);
      }
      setDisabled(false);
    });
  };

  return (
    <div>
      {loading ? (
        <ProductSkeleton />
      ) : (
        <MDBox component="form" onSubmit={updateForm}>
          <Grid container alignItems="center">
            <Grid item xs={12} p={1}>
              <TextField
                fullWidth
                helperText={validateError.f_name ? validateError.f_name : " "}
                error={!!validateError.f_name}
                label="First Name"
                type="text"
                name="f_name"
                onChange={handleInput}
                defaultValue={inputData.f_name}
                variant="standard"
              />
            </Grid>
            <Grid item xs={12} p={1}>
              <TextField
                fullWidth
                helperText={validateError.l_name ? validateError.l_name : " "}
                error={!!validateError.l_name}
                label="Last Name"
                type="text"
                name="l_name"
                onChange={handleInput}
                defaultValue={inputData.l_name}
                variant="standard"
              />
            </Grid>
            <Grid item xs={12} p={1}>
              <TextField
                fullWidth
                helperText={validateError.email ? validateError.email : " "}
                error={!!validateError.email}
                label="Email Id"
                type="email"
                name="email"
                onChange={handleInput}
                defaultValue={inputData.email}
                variant="standard"
              />
            </Grid>
            <Grid item xs={12} p={1}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked={inputData.subscribe_status}
                      name="subscribe_status"
                      onChange={handleCheckbox}
                    />
                  }
                  label={inputData.subscribe_status ? "Subscribed" : "Un Subscribed"}
                  // label="Status"
                />
              </FormGroup>
            </Grid>
          </Grid>

          <Divider />

          <MDBox
            // sx={{
            //   display: "flex",
            //   alignContent: "flex-end",
            // }}
            mb={2}
          >
            <Grid item md={2} mt={4} mb={2}>
              <MDButton
                size="small"
                color="dark"
                variant="gradient"
                type="submit"
                disabled={disabled}
              >
                <Icon>done</Icon>&nbsp;
                {disabled ? "Please Wait" : "Save"}
              </MDButton>
              &nbsp;
              <MDButton size="small" color="light" variant="gradient" onClick={handClose}>
                <Icon>close</Icon>&nbsp; Close
              </MDButton>
            </Grid>
          </MDBox>
        </MDBox>
      )}
    </div>
  );
}

export default EditNewsletter;
