// import * as React from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";

import { useState, useEffect } from "react";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDEditor from "components/MDEditor";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";

import axios from "axios";
import toast from "react-hot-toast";
// import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React contexts
// import { useMaterialUIController } from "context";

import ProductSkeleton from "examples/Skeleton/product";
import imagePlaceholder from "assets/images/image_placeholder.jpeg";

function CreateProduct(props) {
  const { setOpenDrawer, setRefresh, setIssetData } = props;
  const [validateError, setError] = useState([]);
  const [loading, setLoading] = useState(true);
  const [categoryList, setCategoryList] = useState([]);
  const [disabled, setDisabled] = useState(false);

  // const [controller] = useMaterialUIController();
  // const { darkMode } = controller;

  const [inputPicture, setInputPicture] = useState([]);
  const [editorValue, setEditorValue] = useState();
  const [preview, setPreview] = useState();
  const [inputData, setInputData] = useState({
    category_id: "",
    name: "",
    short_description: "",
    long_description: "",
    price: "",
    size: "",
    discount: "",
    discount_type: "percentage",
    stock: "",
    max_qty: "",
    meta_keyword: "",
    tag_trending: "",
    tag_new_arrival: "",
    tag_top_save: "",
  });

  useEffect(() => {
    // setLoading(false);
    setDisabled(false);
    axios.get(`/api/product-create`).then((res) => {
      if (res.data.status === 200) {
        setCategoryList(res.data.category);
      }
      setLoading(false);
    });
  }, []);

  const handleInput = (e) => {
    e.persist();
    setInputData({ ...inputData, [e.target.name]: e.target.value });
  };

  const handleImage = (e) => {
    e.persist();
    setInputPicture({ image: e.target.files[0] });

    const objectUrl = URL.createObjectURL(e.target.files[0]);
    setPreview(objectUrl);
  };

  // const viewEditorValue = (e) => {
  //   e.persist();
  //   setEditorValue(e.target.value);
  // };

  const handleSelect = (event) => {
    setInputData({ ...inputData, [event.target.name]: event.target.value });
  };

  const handleCheckbox = (event) => {
    setInputData({ ...inputData, [event.target.name]: event.target.checked });
  };

  const handClose = () => {
    setOpenDrawer(false);
    setIssetData(false);
  };

  const createForm = (e) => {
    e.preventDefault();

    setDisabled(true);

    // const data = {
    //   category_id: inputData.category_id,
    //   name: inputData.name,
    //   short_description: inputData.short_description,
    //   long_description: inputData.long_description,
    // };

    const formData = new FormData();
    formData.append("image", inputPicture.image);
    formData.append("category_id", inputData.category_id ?? "");
    formData.append("name", inputData.name ?? "");
    formData.append("short_description", inputData.short_description ?? "");
    formData.append("long_description", editorValue ?? "");

    formData.append("price", inputData.price ?? "");
    formData.append("size", inputData.size ?? "");
    formData.append("discount", inputData.discount ?? "");
    formData.append("discount_type", inputData.discount_type ?? "");
    formData.append("stock", inputData.stock ?? "");
    formData.append("max_qty", inputData.max_qty ?? "");
    formData.append("meta_keyword", inputData.meta_keyword ?? "");
    formData.append("tag_trending", inputData.tag_trending ? 1 : "");
    formData.append("tag_new_arrival", inputData.tag_new_arrival ? 1 : "");
    formData.append("tag_top_save", inputData.tag_top_save ? 1 : "");
    formData.append("tag_best_selling", inputData.tag_best_selling ? 1 : "");

    axios.post(`/api/product-store`, formData).then((res) => {
      setError([]);
      if (res.data.status === 200) {
        toast.success(res.data.message);
        setOpenDrawer(false);
        setRefresh(2);
        setIssetData(false);
      } else if (res.data.status === 202) {
        toast.error(res.data.message);
      } else if (res.data.status === 422) {
        toast.error("Please check the fields");
        setError(res.data.errors);
      } else if (res.data.status === 404) {
        toast.error(res.data.message);
      }
      setDisabled(false);
    });
  };

  return (
    <div>
      {loading ? (
        <ProductSkeleton />
      ) : (
        <MDBox component="form" role="form" onSubmit={createForm}>
          <Grid container alignItems="center">
            <Grid item xs={12} p={1}>
              <TextField
                fullWidth
                helperText={validateError.name ? validateError.name : " "}
                error={!!validateError.name}
                label="Name"
                type="text"
                name="name"
                onChange={handleInput}
                defaultValue={inputData.name}
                variant="standard"
              />
            </Grid>
            <Grid item xs={12} p={1}>
              <TextField
                fullWidth
                multiline
                rows={3}
                helperText={validateError.short_description ? validateError.short_description : " "}
                error={!!validateError.short_description}
                label="Short Description"
                type="text"
                name="short_description"
                onChange={handleInput}
                defaultValue={inputData.short_description}
                variant="standard"
              />
            </Grid>
            <Grid item xs={12} md={4} p={1}>
              <TextField
                fullWidth
                helperText={validateError.price ? validateError.price : " "}
                error={!!validateError.price}
                label="Price"
                type="text"
                name="price"
                onChange={handleInput}
                defaultValue={inputData.price}
                variant="standard"
              />
            </Grid>
            <Grid item xs={12} md={4} p={1}>
              <TextField
                fullWidth
                helperText={validateError.size ? validateError.size : " "}
                error={!!validateError.size}
                label="Size (with Unit)"
                type="text"
                name="size"
                onChange={handleInput}
                defaultValue={inputData.size}
                variant="standard"
              />
            </Grid>
            <Grid item xs={12} md={4} p={1}>
              <TextField
                fullWidth
                helperText={validateError.category_id ? validateError.category_id : " "}
                error={!!validateError.category_id}
                label="Category"
                name="category_id"
                defaultValue={inputData.category_id}
                onChange={handleSelect}
                variant="standard"
                style={{ paddingTop: "8px" }}
                select
              >
                {categoryList.map((data) => (
                  <MenuItem value={data.id}>{data.name}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={3} p={1}>
              <TextField
                fullWidth
                helperText={validateError.discount ? validateError.discount : " "}
                error={!!validateError.discount}
                label="Discount"
                type="text"
                name="discount"
                onChange={handleInput}
                defaultValue={inputData.discount}
                variant="standard"
              />
            </Grid>
            <Grid item xs={12} md={3} p={1}>
              <TextField
                fullWidth
                helperText={validateError.discount_type ? validateError.discount_type : " "}
                error={!!validateError.discount_type}
                label="Discount Type"
                name="discount_type"
                defaultValue={inputData.discount_type}
                onChange={handleSelect}
                variant="standard"
                style={{ paddingTop: "8px" }}
                select
              >
                <MenuItem value="price">Price</MenuItem>
                <MenuItem value="percentage">Percentage</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={6} md={3} p={1}>
              <TextField
                fullWidth
                helperText={validateError.stock ? validateError.stock : " "}
                error={!!validateError.stock}
                label="Stock"
                type="number"
                name="stock"
                onChange={handleInput}
                defaultValue={inputData.stock}
                variant="standard"
              />
            </Grid>
            <Grid item xs={6} md={3} p={1}>
              <TextField
                fullWidth
                helperText={validateError.max_qty ? validateError.max_qty : " "}
                error={!!validateError.max_qty}
                label="Max Qty"
                type="number"
                name="max_qty"
                onChange={handleInput}
                defaultValue={inputData.max_qty}
                variant="standard"
              />
            </Grid>
            <Grid item xs={12} p={1}>
              <TextField
                fullWidth
                helperText={validateError.meta_keyword ? validateError.meta_keyword : " "}
                error={!!validateError.meta_keyword}
                label="Keywords"
                type="text"
                name="meta_keyword"
                onChange={handleInput}
                defaultValue={inputData.meta_keyword}
                variant="standard"
              />
            </Grid>
            <Grid item xs={12} md={4} p={1}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked={inputData.tag_trending}
                      name="tag_trending"
                      onChange={handleCheckbox}
                    />
                  }
                  label="Trending Product"
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={4} p={1}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked={inputData.tag_new_arrival}
                      name="tag_new_arrival"
                      onChange={handleCheckbox}
                    />
                  }
                  label="New Arrival"
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={4} p={1}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked={inputData.tag_top_save}
                      name="tag_top_save"
                      onChange={handleCheckbox}
                    />
                  }
                  label="Top Save Today"
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={4} p={1}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked={inputData.tag_best_selling}
                      name="tag_best_selling"
                      onChange={handleCheckbox}
                    />
                  }
                  label="Best Selling"
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} pb={9}>
              <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                  Description&nbsp;&nbsp;
                  <MDTypography variant="caption" fontWeight="regular" color="text">
                    (long)
                  </MDTypography>
                </MDTypography>
              </MDBox>
              <MDEditor
                defaultValue={editorValue}
                onChange={setEditorValue}
                name="long_description"
                style={{ height: "18rem" }}
              />
            </Grid>
            <Grid item xs={12} md={6} py={3} pr={2}>
              {preview ? (
                <MDBox
                  component="img"
                  src={preview}
                  alt="pic"
                  // shadow="sm"
                  sx={{
                    objectFit: "contain",
                    width: "100%",
                    height: "200px",
                    borderRadius: "5px",
                  }}
                />
              ) : (
                <MDBox
                  component="img"
                  src={imagePlaceholder}
                  alt="pic"
                  // shadow="sm"
                  sx={{
                    objectFit: "contain",
                    width: "100%",
                    height: "200px",
                    borderRadius: "5px",
                  }}
                />
              )}
            </Grid>
            <Grid item xs={12} md={6} py={3}>
              <TextField
                fullWidth
                helperText={validateError.image ? validateError.image : " "}
                error={!!validateError.image}
                label="Image (type: image/png, ratio: 1*1)"
                type="file"
                // accept="image/*"
                inputProps={{
                  accept: "image/png, image/svg",
                }}
                name="image"
                multiple
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={handleImage}
                // defaultValue={inputData.image}
                variant="standard"
                sx={{ borderBottom: "none" }}
              />
            </Grid>
          </Grid>
          <Divider />

          <MDBox mb={2}>
            <MDButton
              size="small"
              color="dark"
              variant="gradient"
              type="submit"
              disabled={disabled}
            >
              {disabled ? "Please Wait" : "Submit"}
            </MDButton>
            &nbsp;
            <MDButton size="small" color="light" variant="gradient" onClick={handClose}>
              Close
            </MDButton>
          </MDBox>
        </MDBox>
      )}
    </div>
  );
}

export default CreateProduct;
