/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// react-quill components
import ReactQuill from "react-quill";

// react-quill styles
import "react-quill/dist/quill.snow.css";

// Custom styles for the MDEditor
import MDEditorRoot from "components/MDEditor/MDEditorRoot";

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";

function MDEditor(props) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const toolbarOptions = [
    [{ header: [1, 2, 3, 4, 5, 6, false, "a"] }],
    ["bold", "italic", "underline", "strike"], // toggled buttons
    [{ list: "ordered" }, { list: "bullet" }],
    ["blockquote", "code"],
    ["link", "video"],
    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ align: [] }],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    ["clean"], // remove formatting button
  ];

  return (
    <MDEditorRoot ownerState={{ darkMode }}>
      <ReactQuill
        debug="info"
        modules={{
          toolbar: toolbarOptions,
        }}
        theme="snow"
        {...props}
      />
    </MDEditorRoot>
  );
}

export default MDEditor;
