/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDButton from "components/MDButton";

// Authentication layout components
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";

// Image
import bgImage from "assets/images/illustrations/error-404.png";

function page404() {
  return (
    <IllustrationLayout title="404" description="Url/Page Not Found" illustration={bgImage}>
      <MDBox component="form" role="form">
        {/* <MDBox textAlign="center" mb={1}>
          <MDButton variant="gradient" color="info" size="large" component={Link} to="/" >
          Home Page
          </MDButton>
        </MDBox> */}
        <MDBox textAlign="center">
          <MDTypography variant="button" color="text">
            Page Not Found{" "}
            <MDTypography
              component={Link}
              to="/"
              variant="button"
              color="info"
              fontWeight="medium"
              textGradient
            >
              Home Page
            </MDTypography>
          </MDTypography>
        </MDBox>
      </MDBox>
    </IllustrationLayout>
  );
}

export default page404;
