// import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

// import Input from "@mui/material/Input";
// import IconButton from "@mui/material/IconButton";
// import Visibility from "@mui/icons-material/Visibility";
// import VisibilityOff from "@mui/icons-material/VisibilityOff";
// import InputAdornment from "@mui/material/InputAdornment";

import { useState, useEffect } from "react";
import MDButton from "components/MDButton";
// import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import Divider from "@mui/material/Divider";

import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";

import axios from "axios";
import toast from "react-hot-toast";
// import { useNavigate } from "react-router-dom";

import ProductSkeleton from "examples/Skeleton/product";

function EditDestination(props) {
  const { setOpenDrawer, setRefresh, dataRecord } = props;
  const [validateError, setError] = useState([]);
  const [loading, setLoading] = useState(true);
  const [disabled, setDisabled] = useState(false);

  const [dataInput, setDataInput] = useState({
    name: "",
    email: "",
    password: "",
    role_as: "",
    showPassword: false,
  });
  const [role, setRole] = useState();
  const [roleName, setRoleName] = useState([]);

  const handClose = () => {
    setOpenDrawer(false);
  };

  useEffect(() => {
    axios.get(`/api/users-edit/${dataRecord}`).then((res) => {
      if (res.data.status === 200) {
        setDataInput(res.data.user);
        setRoleName(res.data.user.roles.map((v) => v.id));
        setRole(res.data.roles);
      } else {
        setOpenDrawer(false);
        toast.error(res.data.message);
      }
      setLoading(false);
      setDisabled(false);
    });
  }, []);

  const handleInput = (e) => {
    e.persist();
    setDataInput({ ...dataInput, [e.target.name]: e.target.value });
  };
  const handleInputEvent = (event) => {
    setDataInput({ ...dataInput, [event.target.name]: event.target.value });
  };

  const roleChange = (event) => {
    const {
      target: { value },
    } = event;
    setRoleName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  // const handleClickShowPassword = () => {
  //   setDataInput({
  //     ...dataInput,
  //     showPassword: !dataInput.showPassword,
  //   });
  // };

  // const handleMouseDownPassword = (event) => {
  //   event.preventDefault();
  // };

  const updateForn = (e) => {
    e.preventDefault();

    setDisabled(true);

    const data = {
      name: dataInput.name,
      role_as: dataInput.role_as,
      roles: roleName,
    };

    axios.put(`/api/users-update/${dataRecord}`, data).then((res) => {
      setError([]);
      if (res.data.status === 200) {
        toast.success(res.data.message);
        setOpenDrawer(false);
        setRefresh(2);
      } else if (res.data.status === 202) {
        toast.error(res.data.message);
      } else if (res.data.status === 422) {
        setError(res.data.errors);
        // toast.error("Fill in fields correctly!");
      } else if (res.data.status === 404) {
        toast.error(res.data.message);
      }
      setDisabled(false);
    });
  };

  return (
    <div>
      {loading ? (
        <ProductSkeleton />
      ) : (
        <Box component="form" onSubmit={updateForn}>
          <Box pt={0.5} pb={1}>
            <TextField
              fullWidth
              helperText={validateError.name ? validateError.name : " "}
              error={!!validateError.name}
              label="Name"
              type="text"
              name="name"
              onChange={handleInput}
              defaultValue={dataInput.name}
              variant="standard"
            />
          </Box>
          <Box py={1}>
            <TextField
              fullWidth
              helperText={validateError.email ? validateError.email : " "}
              error={!!validateError.email}
              label="Email"
              type="email"
              name="email"
              onChange={handleInput}
              // InputProps={{
              //   readOnly: true,
              // }}
              defaultValue={dataInput.email}
              variant="standard"
            />
          </Box>
          {/* <Box py={1}>
            <FormControl fullWidth variant="standard" error={!!validateError.password}>
              <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
              <Input
                fullWidth
                helperText={validateError.password ? validateError.password : ""}
                label="Password"
                type={dataInput.showPassword ? "text" : "password"}
                name="password"
                onChange={handleInput}
                defaultValue={dataInput.password}
                variant="standard"
                min="8"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {dataInput.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText id="component-helper-text">
                {validateError.password ? validateError.password : ""}
              </FormHelperText>
            </FormControl>
          </Box> */}
          <Box py={1}>
            <FormControl sx={{ m: 0, width: "100%" }} size="small" error={!!validateError.roles}>
              <InputLabel>Roles</InputLabel>
              <Select
                multiple
                fullWidth
                sx={{ p: 1 }}
                id="roles"
                name="roles"
                value={roleName}
                onChange={roleChange}
                input={<OutlinedInput label="Name" />}
                // MenuProps={MenuProps}
              >
                {role.map((data) => (
                  <MenuItem key={data.id} value={data.id} divider sx={{ my: 0.5 }}>
                    {data.role_name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText error={!!validateError.roles} sx={{ ml: 0 }}>
                {validateError.roles ? validateError.roles : " "}
              </FormHelperText>
            </FormControl>
          </Box>
          <Box py={1}>
            <TextField
              fullWidth
              helperText={validateError.role_as ? validateError.role_as : " "}
              error={!!validateError.role_as}
              label="Status"
              name="role_as"
              defaultValue={dataInput.role_as}
              onChange={handleInputEvent}
              variant="standard"
              style={{ paddingTop: "8px" }}
              select
            >
              <MenuItem value={2}>Admin</MenuItem>
              <MenuItem value={1}>User</MenuItem>
              <MenuItem value={0}>Ban</MenuItem>
            </TextField>
          </Box>

          <Divider />

          <MDBox
            sx={{
              display: "flex",
              justifyContent: "flex-start",
            }}
            my={2}
          >
            <MDButton
              size="small"
              color="dark"
              variant="gradient"
              type="submit"
              disabled={disabled}
            >
              {disabled ? "Please Wait" : "Update"}
            </MDButton>
            &nbsp;
            <MDButton size="small" color="light" variant="gradient" onClick={handClose}>
              Close
            </MDButton>
          </MDBox>
        </Box>
      )}
    </div>
  );
}

export default EditDestination;
