// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
// import MDBadge from "components/MDBadge";
// import MDTypography from "components/MDTypography";

// MUI
import { useState, useEffect } from "react";
import DataTable from "examples/Tables/DataTable";
import MDButton from "components/MDButton";

// @mui material components
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import axios from "axios";
import swal from "sweetalert";
import toast from "react-hot-toast";

// Skeleton
import TableSkeleton from "examples/Skeleton/table";

import DataDrawer from "./drawer";
import Filter from "./form/filter";
import StatusCell from "./components/StatusCell";

function Proforma() {
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(1);
  const [dataList, setDataList] = useState([]);

  const [openDrawer, setOpenDrawer] = useState(false);
  // const [closeDrawer] = useState(anchor, false);
  const [issetData, setIssetData] = useState(false);
  const [dataRecord, setDataRecord] = useState();

  const [openFilter, setOpenFilter] = useState(false); // for filter state

  useEffect(() => {
    setRefresh(1);
    // setDataRecord();
    // setIssetData(false);
    axios.get(`/api/order`).then((res) => {
      if (res.data.status === 200) {
        setDataList(res.data.orders);
      }
      setLoading(false);
    });
  }, [refresh]);

  if (loading) {
    return <TableSkeleton />;
  }

  const openFilterModal = (e) => {
    e.preventDefault();
    setOpenFilter(true);
  };

  // const openAddDrawer = () => {
  //   setOpenDrawer(true);
  // };

  const openEditDrawer = (e, productId) => {
    e.preventDefault();

    setIssetData(true);
    setDataRecord(productId);
    setOpenDrawer(true);
  };

  const deleteData = (e, id) => {
    e.preventDefault();

    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this  data!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        axios.delete(`/api/order-delete/${id}`).then((res) => {
          if (res.data.status === 200) {
            toast.success(res.data.message);
            setRefresh(2);
          } else if (res.data.status === 404) {
            toast.error(res.data.message);
          }
        });
      } else {
        swal({
          title: "Data is safe!",
          text: "Once deleted, you will not be able to recover this data!",
          icon: "info",
          button: false,
          timer: 1000,
        });
      }
    });
  };

  return (
    <>
      <MDBox my={3}>
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
          &nbsp;
          <MDBox display="flex">
            <MDBox>
              <MDButton variant="outlined" color="dark" onClick={openFilterModal}>
                filters&nbsp;
                <Icon>keyboard_arrow_down</Icon>
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
        <Card>
          <DataTable
            canSearch
            isSorted={false}
            pagination={{ variant: "gradient", color: "light", size: "small" }}
            table={{
              columns: [
                { Header: "id", accessor: "id" },
                { Header: "name", accessor: "name" },
                { Header: "email", accessor: "email" },
                { Header: "phone", accessor: "phone" },
                { Header: "invoice no", accessor: "invoice_no" },
                {
                  Header: "status",
                  accessor: "status",
                  Cell: ({ value }) => {
                    let status;
                    if (value === 2) {
                      status = <StatusCell icon="done" color="success" status="Paid" />;
                    } else if (value === 1) {
                      status = <StatusCell icon="close" color="error" status="Not Paid" />;
                    } else if (value === 3) {
                      status = <StatusCell icon="replay" color="dark" status="Refunded" />;
                    } else {
                      status = <StatusCell icon="close" color="error" status="Canceled" />;
                    }
                    return status;
                  },
                },
                { Header: "action", accessor: "action", align: "right" },
              ],
              rows: dataList.map((row) => ({
                id: `#${row.reference_id}`,
                name: row.name,
                email: row.email,
                phone: row.phone,
                invoice_no: row.invoice_number,
                status: row.status,
                action: (
                  <>
                    <Tooltip title="Edit Order" arrow>
                      <MDButton
                        variant="text"
                        onClick={(e) => openEditDrawer(e, row.id)}
                        circular
                        color="info"
                        iconOnly
                      >
                        <Icon>edit</Icon>
                      </MDButton>
                    </Tooltip>
                    <Tooltip title="Delete" arrow>
                      <MDButton
                        variant="text"
                        onClick={(e) => deleteData(e, row.id)}
                        circular
                        color="error"
                        iconOnly
                      >
                        <Icon>delete</Icon>
                      </MDButton>
                    </Tooltip>
                  </>
                ),
              })),
            }}
          />
        </Card>
        {/* <ProductDrawer /> */}
      </MDBox>
      <DataDrawer
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer}
        setRefresh={setRefresh}
        dataRecord={dataRecord}
        issetData={issetData}
        setIssetData={setIssetData}
      />
      <Filter openFilter={openFilter} setOpenFilter={setOpenFilter} setDataList={setDataList} />
    </>
  );
}

export default Proforma;
