/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// react-router components
import { Routes, Route, Navigate, useNavigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Dashboard 2 PRO React examples
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Material Dashboard 2 PRO React themes
import theme from "assets/theme";

// Material Dashboard 2 PRO React Dark Mode themes
import themeDark from "assets/theme-dark";

// Material Dashboard 2 PRO React routes
import routes from "routes";

// Material Dashboard 2 PRO React contexts
import { useMaterialUIController, setMiniSidenav } from "context";

// Images
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";

// Axios
import axios from "axios";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Pages
import Login from "layouts/authentication/sign-in/illustration";
// import Register from "layouts/authentication/sign-up/illustration";
// import Home from "layouts/pages/home";
import Page403 from "layouts/authentication/errors/403";
import Page404 from "layouts/authentication/errors/404";
import Page500 from "layouts/authentication/errors/500";
import ResetPassword from "layouts/authentication/reset-password";
// import swal from "sweetalert";
import toast, { Toaster } from "react-hot-toast";
// import AdminPrivateRoute from "./AdminPrivateRoute";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.post.Accept = "application/json";

axios.defaults.withCredentials = true;
axios.interceptors.request.use((config) => {
  const token = localStorage.getItem("auth_token");
  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

export default function App() {
  const [Authenticated, setAuthenticated] = useState(false);
  const [loading, setloading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    axios.get(`api/checkingAuth`).then((res) => {
      if (res.data.status === 200) {
        setAuthenticated(true);
      }
      setloading(false);
    });

    return () => {
      setAuthenticated(false);
    };
  }, []);

  // eslint-disable-next-line prefer-arrow-callback
  // axios.interceptors.response.use(undefined, function axiosRetryInterceptor(err) {
  axios.interceptors.response.use(undefined, (err) => {
    if (err.response.status === 401) {
      toast.error(err.response.data.message);
      localStorage.removeItem("auth_token");
      localStorage.removeItem("auth_name");
      // navigate(`/`);
      // window.location.reload();
    }
    return Promise.reject(err);
  });

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 403) {
        toast.error(error.response.data.message);
        toast.error(error.response.statusText);
        navigate(`/403`);
      } else if (error.response.status === 402) {
        toast.error(error.response.data.message);
      } else if (error.response.status === 404) {
        toast.error(error.response.statusText);
        navigate(`/404`);
      } else if (error.response.status === 500) {
        toast.error(error.response.statusText);
        navigate(`/500`);
      }
    }
  );

  if (loading) {
    return <h1>Loading...</h1>;
  }

  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }
      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      <Toaster
        toastOptions={{
          style: {
            fontSize: "15px",
          },
        }}
        position="top-right"
        reverseOrder={false}
      />
      {layout === "dashboard" && (
        <>
          {Authenticated ? (
            <>
              <Sidenav
                color={sidenavColor}
                brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
                brandName="Cinna RR LTD"
                routes={routes}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
              <DashboardLayout>
                <DashboardNavbar />
                <Routes>{getRoutes(routes)}</Routes>
                <Footer />
              </DashboardLayout>
            </>
          ) : (
            ``
          )}
          <Configurator />
          {/* {configsButton} */}
        </>
      )}
      {layout === "vr" && <Configurator />}
      <Routes>
        {/* <Route path="/" element={<Home />} /> */}
        <Route path="/403" element={<Page403 />} />
        <Route path="/404" element={<Page404 />} />
        <Route path="/500" element={<Page500 />} />
        <Route
          path="/reset-password"
          element={
            localStorage.getItem("auth_token") ? <Navigate to="/dashboard" /> : <ResetPassword />
          }
        />
        {/* <Route path="*" element={<Navigate to="/" />} /> */}

        <Route
          path="/"
          element={localStorage.getItem("auth_token") ? <Navigate to="/dashboard" /> : <Login />}
        />
        {/* <Route
          path="/register"
          element={localStorage.getItem("auth_token") ? <Navigate to="/" /> : <Register />}
        /> */}
      </Routes>
    </ThemeProvider>
  );
}
