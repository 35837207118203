import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { useState } from "react";
import { DateRangePicker } from "react-date-range";

import axios from "axios";
import toast from "react-hot-toast";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

function EnquiryFilter(props) {
  const { setDataList, setOpenFilter, openFilter } = props;

  const [selectionRange, setSelectionRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const handleClose = () => {
    setOpenFilter(false);
  };

  const createForm = (e) => {
    e.preventDefault();

    const thisClicked = e.currentTarget;
    thisClicked.innerText = "Please Wait";
    thisClicked.setAttribute("disabled", "disabled");

    const data = {
      start_date: selectionRange.map((v) => v.startDate),
      end_date: selectionRange.map((v) => v.endDate),
    };

    axios.post(`/api/newsletter-filter`, data).then((res) => {
      if (res.data.status === 200) {
        setDataList(res.data.newsletter); // change url and data
        setOpenFilter(false);
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
      thisClicked.innerText = "Get";
      thisClicked.removeAttribute("disabled");
    });
  };

  return (
    <div>
      <Dialog open={openFilter} onClose={handleClose} maxWidth="md">
        <DialogContent>
          <MDBox p={1}>
            <DateRangePicker
              ranges={selectionRange}
              onChange={(item) => setSelectionRange([item.selection])}
              direction="vertical"
              scroll={{ enabled: true }}
              // dateDisplayFormat="dd, MM, yyyy"
            />
          </MDBox>
        </DialogContent>
        <DialogActions>
          <MDButton size="small" color="light" variant="gradient" onClick={handleClose}>
            Cancel
          </MDButton>
          <MDButton size="small" color="dark" variant="gradient" onClick={createForm}>
            Get
          </MDButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default EnquiryFilter;
