// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDBadge from "components/MDBadge";
import Switch from "@mui/material/Switch";

// import MDTypography from "components/MDTypography";

// MUI
import { useState, useEffect } from "react";
import DataTable from "examples/Tables/DataTable";
import MDButton from "components/MDButton";

// @mui material components
import Icon from "@mui/material/Icon";
import axios from "axios";
import Chip from "@mui/material/Chip";

// Skeleton
import TableSkeleton from "examples/Skeleton/table";

import swal from "sweetalert";
import toast from "react-hot-toast";

import DataDrawer from "./drawer";
import Filter from "./form/filter";

function Users() {
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(1);
  const [dataList, setDataList] = useState([]);

  const [openDrawer, setOpenDrawer] = useState(false);
  // const [closeDrawer] = useState(anchor, false);
  const [issetData, setIssetData] = useState(false);
  const [dataRecord, setDataRecord] = useState();
  const [openFilter, setOpenFilter] = useState(false);

  useEffect(() => {
    setDataRecord();
    setIssetData(false);
    axios.get(`/api/users`).then((res) => {
      if (res.data.status === 200) {
        setDataList(res.data.users);
        setRefresh(1);
      }
      setLoading(false);
    });
  }, [refresh]);

  if (loading) {
    return <TableSkeleton />;
  }

  const openAddDrawer = () => {
    setOpenDrawer(true);
    //   setUserRecordForEdit(user);
  };

  const openFilterModal = (e) => {
    e.preventDefault();

    setOpenFilter(true);
  };

  const openEditDrawer = (e, productId) => {
    e.preventDefault();

    setIssetData(true);
    setDataRecord(productId);
    setOpenDrawer(true);
  };

  const handleStatus = (event, id) => {
    const data = {
      status: event.target.checked,
    };

    axios.put(`/api/users-update-status/${id}`, data).then((res) => {
      if (res.data.status === 200) {
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
    });
  };

  const deleteData = (e, id) => {
    e.preventDefault();

    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this  data!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        axios.delete(`/api/users-delete/${id}`).then((res) => {
          if (res.data.status === 200) {
            toast.success(res.data.message);
            setRefresh(2);
          } else if (res.data.status === 404) {
            toast.error(res.data.message);
          }
        });
      } else {
        swal({
          title: "Data is safe!",
          text: "Once deleted, you will not be able to recover this data!",
          icon: "info",
          button: false,
          timer: 1000,
        });
      }
    });
  };

  return (
    <>
      <MDBox my={3}>
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-end" mb={2}>
          <MDButton variant="gradient" color="info" onClick={openAddDrawer}>
            <Icon>add_circle</Icon>
            &nbsp; add
          </MDButton>
          <MDBox display="flex">
            <MDBox ml={1}>
              <MDButton variant="outlined" color="dark" onClick={openFilterModal}>
                filters&nbsp;
                <Icon>keyboard_arrow_down</Icon>
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
        <Card>
          <DataTable
            canSearch
            isSorted={false}
            pagination={{ variant: "gradient", color: "light", size: "small" }}
            table={{
              columns: [
                { Header: "name", accessor: "name" },
                { Header: "email", accessor: "email" },
                { Header: "roles", accessor: "roles" },
                { Header: "state", accessor: "state", align: "center" },
                {
                  Header: "status",
                  accessor: "status",
                  Cell: ({ value }) => {
                    let status;
                    if (value === 2) {
                      status = <MDBadge badgeContent="Admin" container color="success" size="xs" />;
                    } else if (value === 1) {
                      status = <MDBadge badgeContent="User" container color="dark" size="xs" />;
                    } else {
                      status = <MDBadge badgeContent="Banned" container color="error" size="xs" />;
                    }
                    return status;
                  },
                },
                { Header: "action", accessor: "action", align: "right" },
              ],
              rows: dataList.map((row) => ({
                name: row.name,
                email: row.email,
                roles: row.roles.map((role) => <Chip sx={{ mr: 0.1 }} label={role.role_name} />),
                status: row.role_as,
                state: (
                  <Switch
                    defaultChecked={row.role_as !== 0}
                    onChange={(event) => handleStatus(event, row.id)}
                  />
                ),
                action: (
                  <>
                    <MDButton
                      variant="text"
                      onClick={(e) => openEditDrawer(e, row.id)}
                      circular
                      color="warning"
                      iconOnly
                    >
                      <Icon>edit</Icon>
                    </MDButton>
                    <MDButton
                      style={{ marginLeft: "10px" }}
                      variant="text"
                      circular
                      color="error"
                      iconOnly
                      onClick={(e) => deleteData(e, row.id)}
                    >
                      <Icon>delete</Icon>
                    </MDButton>
                  </>
                ),
              })),
            }}
          />
        </Card>
        {/* <ProductDrawer /> */}
      </MDBox>
      <DataDrawer
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer}
        setRefresh={setRefresh}
        dataRecord={dataRecord}
        issetData={issetData}
        setIssetData={setIssetData}
      />
      <Filter openFilter={openFilter} setOpenFilter={setOpenFilter} setDataList={setDataList} />
    </>
  );
}

export default Users;
