import Drawer from "@mui/material/Drawer";
import MDBox from "components/MDBox";

import MDTypography from "components/MDTypography";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

import { useTheme } from "@mui/material/styles";
import CreateForm from "../form/create";
import EditForm from "../form/edit";

// import { useEffect, useState } from "react";

function DataDrawer(props) {
  const { openDrawer, setOpenDrawer, dataRecord, setRefresh, issetData, setIssetData } = props;

  const handleClose = () => {
    setOpenDrawer(false);
    setIssetData(false);
  };

  const theme = useTheme();

  return (
    <div>
      <Drawer
        hideBackdrop
        transitionDuration={{ enter: 450, exit: 300 }}
        anchor="right"
        open={openDrawer}
        onClose={handleClose}
        // onOpen={toggleDrawer(anchor, true)}
        style={{ borderRadius: 0 }}
        PaperProps={{
          sx: {
            width: 360,
            // width: "20rem",
            borderRadius: 0,
            margin: 0,
            height: "100vh",
            [theme.breakpoints.up("md")]: {
              width: 720,
            },
          },
        }}
      >
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="baseline"
          pt={4}
          pb={0.5}
          px={3}
        >
          <MDBox>
            <MDTypography variant="h5">{issetData ? "Edit Order" : "Create Order"}</MDTypography>
            <MDTypography variant="body2" color="text">
              submit you order details
            </MDTypography>
          </MDBox>

          <Icon
            sx={{
              strokeWidth: "2px",
              cursor: "pointer",
              transform: "translateY(5px)",
            }}
            onClick={handleClose}
          >
            close
          </Icon>
        </MDBox>

        <Divider />
        <MDBox py={1} px={3}>
          {issetData ? (
            <EditForm
              setOpenDrawer={setOpenDrawer}
              dataRecord={dataRecord}
              setRefresh={setRefresh}
              setIssetData={setIssetData}
            />
          ) : (
            <CreateForm
              setOpenDrawer={setOpenDrawer}
              setRefresh={setRefresh}
              setIssetData={setIssetData}
            />
          )}
        </MDBox>
      </Drawer>
    </div>
  );
}

export default DataDrawer;
