// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDBadge from "components/MDBadge";
// import MDTypography from "components/MDTypography";

// MUI
import { useState, useEffect } from "react";
import Tooltip from "@mui/material/Tooltip";
import Switch from "@mui/material/Switch";

import DataTable from "examples/Tables/DataTable";
import MDButton from "components/MDButton";

// @mui material components
import Icon from "@mui/material/Icon";
import axios from "axios";
import dateFormat from "dateformat";
import swal from "sweetalert";
import toast from "react-hot-toast";

// Skeleton
import TableSkeleton from "examples/Skeleton/table";

import DataDrawer from "./drawer";
import Filter from "./form/filter";

function Buyer() {
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(1);
  const [dataList, setDataList] = useState([]);

  const [openDrawer, setOpenDrawer] = useState(false);
  // const [closeDrawer] = useState(anchor, false);
  const [issetData, setIssetData] = useState(false);
  const [dataRecord, setDataRecord] = useState();
  const [openFilter, setOpenFilter] = useState(false);

  useEffect(() => {
    setDataRecord();
    setIssetData(false);
    axios.get(`/api/category`).then((res) => {
      if (res.data.status === 200) {
        setDataList(res.data.category);
        setRefresh(1);
      }
      setLoading(false);
    });
  }, [refresh]);

  if (loading) {
    return <TableSkeleton />;
  }

  const openAddDrawer = () => {
    setOpenDrawer(true);
    //   setUserRecordForEdit(user);
  };

  // const openFilterModal = (e) => {
  //   e.preventDefault();
  //   setOpenFilter(true);
  // };

  const openEditDrawer = (e, productId) => {
    e.preventDefault();

    setIssetData(true);
    setDataRecord(productId);
    setOpenDrawer(true);
  };

  const handleStatus = (event, id) => {
    const data = {
      status: event.target.checked,
    };

    axios.put(`/api/category-update-status/${id}`, data).then((res) => {
      if (res.data.status === 200) {
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
    });
  };

  const deleteData = (e, id) => {
    e.preventDefault();

    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this  data!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        axios.delete(`/api/category-delete/${id}`).then((res) => {
          if (res.data.status === 200) {
            toast.success(res.data.message);
            setRefresh(2);
          } else if (res.data.status === 404) {
            toast.error(res.data.message);
          }
        });
      } else {
        swal({
          title: "Data is safe!",
          text: "Once deleted, you will not be able to recover this data!",
          icon: "info",
          button: false,
          timer: 1000,
        });
      }
    });
  };

  return (
    <>
      <MDBox my={3}>
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
          <MDButton variant="gradient" color="info" onClick={openAddDrawer}>
            <Icon>add_circle</Icon>
            &nbsp; add
          </MDButton>
          <MDBox display="flex">
            <MDBox>&nbsp;</MDBox>
          </MDBox>
        </MDBox>
        <Card>
          <DataTable
            canSearch
            isSorted={false}
            pagination={{ variant: "gradient", color: "light", size: "small" }}
            table={{
              columns: [
                { Header: "name", accessor: "name" },
                { Header: "icon", accessor: "icon" },
                { Header: "products", accessor: "products", align: "center" },
                { Header: "created at", accessor: "created_at" },
                { Header: "status", accessor: "status" },
                { Header: "action", accessor: "action", align: "right" },
              ],
              rows: dataList.map((row) => ({
                name: row.name,
                products: <MDBadge badgeContent={row.products_count} container color="secondary" />,
                created_at: dateFormat(row.created_at, "dd/mm/yyyy"),
                status: (
                  <Switch
                    defaultChecked={row.status === 1}
                    onChange={(event) => handleStatus(event, row.id)}
                  />
                ),
                icon: (
                  <MDBox
                    component="img"
                    src={`${process.env.REACT_APP_API_URL}/${row.icon}`}
                    alt="pic"
                    sx={{
                      objectFit: "cover",
                      width: "40px",
                      height: "auto",
                      borderRadius: "5px",
                      background: "#ffffff8c",
                    }}
                  />
                ),
                action: (
                  <>
                    <Tooltip title="Edit" arrow>
                      <MDButton
                        variant="text"
                        onClick={(e) => openEditDrawer(e, row.id)}
                        circular
                        color="warning"
                        iconOnly
                      >
                        <Icon>edit</Icon>
                      </MDButton>
                    </Tooltip>
                    &nbsp;
                    <Tooltip title="Delete" arrow>
                      <MDButton
                        // style={{ marginLeft: "10px" }}
                        variant="text"
                        circular
                        color="error"
                        iconOnly
                        onClick={(e) => deleteData(e, row.id)}
                      >
                        <Icon>delete</Icon>
                      </MDButton>
                    </Tooltip>
                  </>
                ),
              })),
            }}
          />
        </Card>
        {/* <ProductDrawer /> */}
      </MDBox>
      <DataDrawer
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer}
        setRefresh={setRefresh}
        dataRecord={dataRecord}
        issetData={issetData}
        setIssetData={setIssetData}
      />
      <Filter openFilter={openFilter} setOpenFilter={setOpenFilter} setDataList={setDataList} />
    </>
  );
}

export default Buyer;
