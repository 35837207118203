// import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
// import MenuItem from "@mui/material/MenuItem";

import { useState, useEffect } from "react";
import MDButton from "components/MDButton";
// import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import Divider from "@mui/material/Divider";

import axios from "axios";
import toast from "react-hot-toast";
// import { useNavigate } from "react-router-dom";
// import Autocomplete from "@mui/material/Autocomplete";

import ProductSkeleton from "examples/Skeleton/product";

function CreateManufacturer(props) {
  const { setOpenDrawer, setRefresh, setIssetData } = props;
  const [validateError, setError] = useState([]);
  const [loading, setLoading] = useState(true);
  const [disabled, setDisabled] = useState(false);

  // const navigate = useNavigate();

  const [inputData, setInputData] = useState({
    manufacturer_name: "",
    manufacturer_address: "",
    manufacturer_licence_no: "",
    manufacturer_gst_no: "",
    manufacturer_pan_no: "",
    manufacturer_lut_arn_no: "",
    manufacturer_iec_no: "",
    manufacturer_tin_no: "",
    manufacturer_cst_no: "",
    manufacturer_phone_no: "",
    manufacturer_fssai_no: "",
  });

  useEffect(() => {
    setLoading(false);
    // axios.get(`/api/destination-fetch-countries`).then((res) => {
    //   if (res.data.status === 200) {
    //     setCountry(res.data.countries);
    //   }
    //   setLoading(false);
    // });
  }, []);

  const handleInput = (e) => {
    e.persist();
    setInputData({ ...inputData, [e.target.name]: e.target.value });
  };

  const handClose = () => {
    setOpenDrawer(false);
    setIssetData(false);
  };

  const createForm = (e) => {
    e.preventDefault();

    setDisabled(true);

    const data = {
      manufacturer_name: inputData.manufacturer_name,
      manufacturer_address: inputData.manufacturer_address,
      manufacturer_licence_no: inputData.manufacturer_licence_no,
      manufacturer_gst_no: inputData.manufacturer_gst_no,
      manufacturer_pan_no: inputData.manufacturer_pan_no,
      manufacturer_lut_arn_no: inputData.manufacturer_lut_arn_no,
      manufacturer_iec_no: inputData.manufacturer_iec_no,
      manufacturer_tin_no: inputData.manufacturer_tin_no,
      manufacturer_cst_no: inputData.manufacturer_cst_no,
      manufacturer_phone_no: inputData.manufacturer_phone_no,
      manufacturer_fssai_no: inputData.manufacturer_fssai_no,
    };

    axios.post(`/api/manufacturer-store`, data).then((res) => {
      setError([]);
      if (res.data.status === 200) {
        // console.log(res.data.message);
        toast.success(res.data.message);
        setOpenDrawer(false);
        setRefresh(2);
        setIssetData(false);
      } else if (res.data.status === 202) {
        toast.error(res.data.message);
      } else if (res.data.status === 422) {
        setError(res.data.errors);
      } else if (res.data.status === 404) {
        toast.error(res.data.message);
      }
      setDisabled(false);
    });
  };

  return (
    <div>
      {loading ? (
        <ProductSkeleton />
      ) : (
        <Box component="form" onSubmit={createForm}>
          <MDBox py={1}>
            <TextField
              fullWidth
              helperText={validateError.manufacturer_name ? validateError.manufacturer_name : " "}
              error={!!validateError.manufacturer_name}
              label="Name"
              type="text"
              name="manufacturer_name"
              onChange={handleInput}
              defaultValue={inputData.manufacturer_name}
              variant="standard"
            />
          </MDBox>
          <MDBox py={1}>
            <TextField
              fullWidth
              multiline
              rows={3}
              helperText={
                validateError.manufacturer_address ? validateError.manufacturer_address : " "
              }
              error={!!validateError.manufacturer_address}
              label="Address"
              type="text"
              name="manufacturer_address"
              onChange={handleInput}
              defaultValue={inputData.manufacturer_address}
              variant="standard"
            />
          </MDBox>
          <MDBox py={1}>
            <TextField
              fullWidth
              helperText={
                validateError.manufacturer_licence_no ? validateError.manufacturer_licence_no : " "
              }
              error={!!validateError.manufacturer_licence_no}
              label="Licence No"
              type="text"
              name="manufacturer_licence_no"
              onChange={handleInput}
              defaultValue={inputData.manufacturer_licence_no}
              variant="standard"
            />
          </MDBox>
          <MDBox py={1}>
            <TextField
              fullWidth
              helperText={
                validateError.manufacturer_gst_no ? validateError.manufacturer_gst_no : " "
              }
              error={!!validateError.manufacturer_gst_no}
              label="GST In No"
              type="text"
              name="manufacturer_gst_no"
              onChange={handleInput}
              defaultValue={inputData.manufacturer_gst_no}
              variant="standard"
            />
          </MDBox>
          <MDBox py={1}>
            <TextField
              fullWidth
              helperText={
                validateError.manufacturer_cst_no ? validateError.manufacturer_cst_no : " "
              }
              error={!!validateError.manufacturer_cst_no}
              label="CST No"
              type="text"
              name="manufacturer_cst_no"
              onChange={handleInput}
              defaultValue={inputData.manufacturer_cst_no}
              variant="standard"
            />
          </MDBox>
          <MDBox py={1}>
            <TextField
              fullWidth
              helperText={
                validateError.manufacturer_pan_no ? validateError.manufacturer_pan_no : " "
              }
              error={!!validateError.manufacturer_pan_no}
              label="PAN No"
              type="text"
              name="manufacturer_pan_no"
              onChange={handleInput}
              defaultValue={inputData.manufacturer_pan_no}
              variant="standard"
            />
          </MDBox>
          <MDBox py={1}>
            <TextField
              fullWidth
              helperText={
                validateError.manufacturer_lut_arn_no ? validateError.manufacturer_lut_arn_no : " "
              }
              error={!!validateError.manufacturer_lut_arn_no}
              label="LUT ARN No"
              type="text"
              name="manufacturer_lut_arn_no"
              onChange={handleInput}
              defaultValue={inputData.manufacturer_lut_arn_no}
              variant="standard"
            />
          </MDBox>
          <MDBox py={1}>
            <TextField
              fullWidth
              helperText={
                validateError.manufacturer_iec_no ? validateError.manufacturer_iec_no : " "
              }
              error={!!validateError.manufacturer_iec_no}
              label="IEC No"
              type="text"
              name="manufacturer_iec_no"
              onChange={handleInput}
              defaultValue={inputData.manufacturer_iec_no}
              variant="standard"
            />
          </MDBox>
          <MDBox py={1}>
            <TextField
              fullWidth
              helperText={
                validateError.manufacturer_tin_no ? validateError.manufacturer_tin_no : " "
              }
              error={!!validateError.manufacturer_tin_no}
              label="TIN No"
              type="text"
              name="manufacturer_tin_no"
              onChange={handleInput}
              defaultValue={inputData.manufacturer_tin_no}
              variant="standard"
            />
          </MDBox>
          <MDBox py={1}>
            <TextField
              fullWidth
              helperText={
                validateError.manufacturer_phone_no ? validateError.manufacturer_phone_no : " "
              }
              error={!!validateError.manufacturer_phone_no}
              label="Phone No"
              type="tel"
              name="manufacturer_phone_no"
              onChange={handleInput}
              defaultValue={inputData.manufacturer_phone_no}
              variant="standard"
            />
          </MDBox>
          <MDBox py={1}>
            <TextField
              fullWidth
              helperText={
                validateError.manufacturer_fssai_no ? validateError.manufacturer_fssai_no : " "
              }
              error={!!validateError.manufacturer_fssai_no}
              label="Fssai No"
              type="text"
              name="manufacturer_fssai_no"
              onChange={handleInput}
              defaultValue={inputData.manufacturer_fssai_no}
              variant="standard"
            />
          </MDBox>

          <Divider />

          <MDBox
            // sx={{
            //   display: "flex",
            //   alignContent: "flex-end",
            // }}
            mb={2}
          >
            <MDButton
              size="small"
              color="dark"
              variant="gradient"
              type="submit"
              disabled={disabled}
            >
              {disabled ? "Please Wait" : "Submit"}
            </MDButton>
            &nbsp;
            <MDButton size="small" color="light" variant="gradient" onClick={handClose}>
              Close
            </MDButton>
          </MDBox>
        </Box>
      )}
    </div>
  );
}

export default CreateManufacturer;
