/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 PRO React layouts
import Analytics from "layouts/dashboards/analytics";
import AllUser from "layouts/admin/users";

import Category from "layouts/pages/category";
import Products from "layouts/pages/products";

import Manufacturer from "layouts/factory/manufacturer";
// import Orders from "layouts/factory/orders";

// import Proforma from "layouts/finance/proforma";
// import Invoice from "layouts/finance/invoice";
import Order from "layouts/finance/order";
import Discount from "layouts/pages/discount";
import ContactUs from "layouts/pages/contact";
import Newsletter from "layouts/pages/newsletter";
import Banner from "layouts/pages/banner";

// import Banner from "layouts/ecommerce/orders/order-list";
// Material Dashboard 2 PRO React components
// import MDAvatar from "components/MDAvatar";

// @mui icons
import Icon from "@mui/material/Icon";

// Images
// import profilePicture from "assets/images/team-2.jpg";

const routes = [
  {
    role: "all",
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    route: "dashboard",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    component: <Analytics />,
    noCollapse: true,
  },
  { type: "divider", key: "divider-0" },
  { type: "title", title: "Admin", key: "admin", role: "admin" },
  {
    role: "admin",
    type: "collapse",
    name: "Users",
    key: "users",
    route: "/users",
    component: <AllUser />,
    icon: <Icon fontSize="medium">people</Icon>,
    noCollapse: true,
  },
  { type: "divider", key: "divider-1", role: "pages" },
  { type: "title", title: "Pages", key: "pages", role: "pages" },
  {
    role: "pages",
    type: "collapse",
    name: "Category",
    key: "category",
    route: "/category",
    component: <Category />,
    icon: <Icon fontSize="medium">category</Icon>,
    noCollapse: true,
  },
  {
    role: "pages",
    type: "collapse",
    name: "Product",
    key: "products",
    route: "/products",
    component: <Products />,
    icon: <Icon fontSize="medium">inventory</Icon>,
    noCollapse: true,
  },
  {
    role: "pages",
    type: "collapse",
    name: "Banner",
    key: "banner",
    route: "/banner",
    component: <Banner />,
    icon: <Icon fontSize="medium">featured_video</Icon>,
    noCollapse: true,
  },
  {
    role: "pages",
    type: "collapse",
    name: "Discount",
    key: "discount",
    route: "/discount",
    component: <Discount />,
    icon: <Icon fontSize="medium">discount</Icon>,
    noCollapse: true,
  },
  { type: "divider", key: "divider-2", role: "emails" },
  { type: "title", title: "Emails", key: "emails", role: "emails" },
  {
    role: "pages",
    type: "collapse",
    name: "Contact Us",
    key: "contact",
    route: "/contact",
    component: <ContactUs />,
    icon: <Icon fontSize="medium">contact_support</Icon>,
    noCollapse: true,
  },
  {
    role: "pages",
    type: "collapse",
    name: "Newsletter",
    key: "newsletter",
    route: "/newsletter",
    component: <Newsletter />,
    icon: <Icon fontSize="medium">newspaper</Icon>,
    noCollapse: true,
  },
  { type: "divider", key: "divider-3", role: "finance" },
  { type: "title", title: "Finance", key: "finance", role: "finance" },
  {
    role: "finance",
    type: "collapse",
    name: "Order",
    key: "order",
    route: "/order",
    component: <Order />,
    icon: <Icon fontSize="medium">receipt_long</Icon>,
    noCollapse: true,
  },
  { type: "divider", key: "divider-4", role: "factory" },
  { type: "title", title: "Factory", key: "factory", role: "factory" },
  {
    role: "factory",
    type: "collapse",
    name: "Manufacturer",
    key: "manufacturer",
    route: "/manufacturer",
    component: <Manufacturer />,
    icon: <Icon fontSize="medium">factory</Icon>,
    noCollapse: true,
  },
  // {
  //   role: "factory",
  //   type: "collapse",
  //   name: "Orders",
  //   key: "orders",
  //   route: "/orders",
  //   component: <Orders />,
  //   icon: <Icon fontSize="medium">grading</Icon>,
  //   noCollapse: true,
  // },

  // {
  //   type: "collapse",
  //   name: "Change Log",
  //   key: "changelog",
  //   href: "https://github.com/creativetimofficial/ct-material-dashboard-pro-react/blob/main/CHANGELOG.md",
  //   icon: <Icon fontSize="medium">receipt_long</Icon>,
  //   noCollapse: true,
  // },
];

export default routes;
